import { TopicMaterialTypeId } from "@interfaces";

/**
 * Return the Icon value for a given TopicMaterialTypeId
 */
export function getIconForTopicMaterialType(topicMaterialTypeId: TopicMaterialTypeId): string {


	if (topicMaterialTypeId == TopicMaterialTypeId.Blog) return `fa-regular fa-file-spreadsheet`;
	if (topicMaterialTypeId == TopicMaterialTypeId.Doc) return `fa-regular fa-file-powerpoint`;
	if (topicMaterialTypeId == TopicMaterialTypeId.Handout) return `fa-regular fa-file`;
	if (topicMaterialTypeId == TopicMaterialTypeId.Pdf) return `fa-regular fa-file-pdf`;
	if (topicMaterialTypeId == TopicMaterialTypeId.Slide) return `fa-regular fa-file-doc`;
	if (topicMaterialTypeId == TopicMaterialTypeId.Spreadsheet) return `fa-regular fa-video`;
	if (topicMaterialTypeId == TopicMaterialTypeId.Video) return `fa-regular fa-link`;
	if (topicMaterialTypeId == TopicMaterialTypeId.Weblink) return `fa-regular fa-link`;

	
	return '';
}