import { inject } from "@angular/core";
import { UrlService } from '@eforall/common';


export abstract class BaseLabelService {

	private readonly urlService = inject(UrlService);

	protected getLabel(labels: string[]) {
		return !this.urlService.isSpanish() ? labels[0] : labels[1];
	}

}