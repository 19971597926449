<mobile-page-part>

	<div class="info-content">
		<h3>Example Fields</h3>
	</div>

	<common-date-time-field [field]="date"></common-date-time-field>
	<common-date-time-field [field]="dateTime"></common-date-time-field>
	<common-select-field [field]="races"></common-select-field>
	<common-multiselect-field [field]="racesMultiple"></common-multiselect-field>
	<common-phone-field [field]="phone"></common-phone-field>
	<common-text-field [field]="textarea"></common-text-field>
	<common-text-field [field]="text"></common-text-field>
	<common-number-field [field]="number"></common-number-field>
	<common-number-field [field]="dollar"></common-number-field>
	<common-number-field [field]="percentage"></common-number-field>
	<common-email-field [field]="email"></common-email-field>
	<common-url-field [field]="url"></common-url-field>
	<common-address-field [field]="address"></common-address-field>

</mobile-page-part>