import { Component, inject } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterLink } from '@angular/router';
import { CommonButtonRowWidget } from '@eforall/common';
import { MobileFrameService, MobilePagePart } from '@eforall/mobile';
import { CohortLabelsService } from './cohort-labels.service';

@Component({
	selector: 'cohort-page',
	standalone: true,
	imports: [
		MobilePagePart,
		RouterLink,
		CommonButtonRowWidget,
		FormsModule,
	],
	templateUrl: './cohort.page.html'
})

export class CohortPage {
	private frame = inject(MobileFrameService);
	public labels = inject(CohortLabelsService);

	constructor() {
		this.frame.setUrlMetadata({ url: `/cohort`, pageName: 'Cohort', backUrl: `/` });
	}

}