import { NgClass } from '@angular/common';
import { Component, OnInit, computed, inject, signal } from '@angular/core';
import { RouterLink } from '@angular/router';
import { CommonAddressFieldWidget, CommonButtonColumnWidget, CommonDateTimeFieldWidget, CommonMultiselectFieldWidget, CommonNumberFieldWidget, CommonPhoneFieldWidget, CommonSelectFieldWidget, CommonTextFieldWidget } from '@eforall/common';
import { MobilePagePart } from '@eforall/mobile';
import { ApplicationAboutYouFormService } from './about-you-form.service';


@Component({
	selector: 'application-about-you-part',
	standalone: true,
	imports: [
		MobilePagePart,
		CommonTextFieldWidget,
		CommonSelectFieldWidget,
		CommonNumberFieldWidget,
		CommonPhoneFieldWidget,
		CommonDateTimeFieldWidget,
		CommonMultiselectFieldWidget,
		CommonAddressFieldWidget,
		RouterLink,
		NgClass,
		CommonButtonColumnWidget,
	],
	templateUrl: './application-about-you.part.html'
})

export class ApplicationAboutYouPart implements OnInit {
	public form = inject(ApplicationAboutYouFormService);


	public readonly showOtherGender = computed(() => {
		if (this.form.gender.actualValue() === 'O') return true;
		else return false;
	});

	public instructions = computed(() => 'In order to best serve you, we would love to learn more about you. No personally identifiable information will be shared with any external, third party without your authorization. As a nonprofit, EforAll needs aggregated (non-personally identifiable) demographic data to apply for grants and other fundraising opportunities that enable us to offer programs at no cost.');
	private instructionClicked = signal(false);

	public primaryBtn = computed<'next-step' | 'instructions' | 'none'>(() => {
		if (this.form.aboutYouComplete()) {
			return 'next-step';
		}

		const isClicked = this.instructionClicked();
		if (!isClicked) return 'instructions';
		return 'none';
	});


	ngOnInit() {
		const applicationId = this.form.applicationService.applicationId();
		const key = `application-${applicationId}-about-you`;
		const isClicked = this.form.applicationService.getInstructionKey(key);
		this.instructionClicked.set(isClicked);
	}

	openInstructions() {
		const applicationId = this.form.applicationService.applicationId();
		if (applicationId) {
			const key = `application-${applicationId}-about-you`;

			const stored = this.form.applicationService.getInstructionKey(key);
			if (!stored) {
				this.form.applicationService.setInstructionKey(key, 'clicked');
				this.instructionClicked.set(true);
			}
			this.form.applicationService.openInstructionFlyout(this.instructions());
		}

	}
}