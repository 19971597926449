import { Component, computed, inject } from '@angular/core';
import { RouterLink } from '@angular/router';
import { CommonButtonColumnWidget, CommonMessageWidget, UrlService } from '@eforall/common';
import { MobileFrameService, MobilePagePart } from '@eforall/mobile';
import { ApplicationStatusId, LanguageId, OpenApplicationStatusIds } from '@interfaces';
import { AppService } from '../../../../services';
import { ApplicationApplyPart } from '../apply.part';
import { ApplicationApplyService } from '../apply.service';
import { PendingApplication } from '../interfaces';

@Component({
	selector: 'applications-apply-accelerator-page',
	standalone: true,
	imports: [
		MobilePagePart,
		ApplicationApplyPart,
		RouterLink,
		CommonButtonColumnWidget,
		CommonMessageWidget,
	],
	templateUrl: './apply-accelerator.page.html'
})

export class ApplicationApplyAcceleratorPage {
	private frame = inject(MobileFrameService);
	private urlService = inject(UrlService);
	private app = inject(AppService);
	public applyService = inject(ApplicationApplyService);

	public infoText = computed<string>(() => !this.urlService.isSpanish() ? '### Apply for an upcoming Business Accelerator\n\n\nThank you for your interest in EforAll. To locate available programs, we need to collect some basic information.This information will never be shared with any external third parties.' : '### Solicite una próxima Aceleradora de Negocios\n\n\nGracias por su interés en EforAll. Para localizar los programas disponibles, necesitamos recopilar información básica. Esta información nunca se compartirá con terceros externos.');


	constructor() {
		this.frame.setUrlMetadata({ url: '/applications/apply-accelerator', pageName: 'Apply', backUrl: '/applications' });
	}

}