<mobile-page-part>

	<div class="info-content">
		<h3>{{feedbackInfo()}}</h3>
	</div>

	<div class="white-box">
		{{labels.feedbackOverview()}}
	</div>




	@if(!feedback().length){
	<h4 class="text-center">No Feedback available yet</h4>
	}
	@else {
	<div>
		<h4>
			Feedback for
		</h4>
		<select class="form-select form-select-sm" [ngModel]="selectedFeedback()?.key"
			(ngModelChange)="onChange($event)">
			<option disabled value="">Select...</option>
			@for(option of feedback(); track option.key;)
			{
			<option [value]="option.key">{{option.siteName}} {{option.programInstanceName}} {{option.programName}}
			</option>
			}
		</select>
	</div>

	@if(selectedFeedback(); as selectedFeedback){
	@for(data of selectedFeedback.feedback; track $index;){
	<!-- <h3>{{data.reviewerLabel}} <small>({{data.polishedFeedback.length}})</small></h3> -->
	@for(feedback of data.polishedFeedback; track $index; let idx = $index){
	<div class="white-box">
		<h4>{{data.reviewerLabel}} | {{idx+1}} of {{data.polishedFeedback.length}}</h4>
		{{feedback}}
	</div>
	}
	}
	}
	}

</mobile-page-part>