import { NgClass } from '@angular/common';
import { Component, computed, inject, OnInit, signal } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CommonButtonColumnWidget, CommonMessageWidget } from '@eforall/common';
import { MobileFrameService, MobileListWidget, MobileListWithSubItem, MobilePagePart } from '@eforall/mobile';
import { ApplicationService } from './application.service';
import { ApplicationReadonlyPart } from './readonly/application-readonly.part';

@Component({
	selector: 'application-page',
	standalone: true,
	imports: [
		MobilePagePart,
		CommonButtonColumnWidget,
		CommonMessageWidget,
		ApplicationReadonlyPart,
		MobileListWidget,
		NgClass,
	],
	templateUrl: './application.page.html'
})

export class ApplicationPage implements OnInit {
	private frame = inject(MobileFrameService);
	public applicationService = inject(ApplicationService);
	private route = inject(ActivatedRoute);

	public instructions = computed(() => {
		const application = this.applicationService.application()?.application;
		return `Congratulations on taking a big step forward for your business: applying to an EforAll ${application?.programName} program.  It is important that you provide clear and detailed information about your business idea so your application can be properly evaluated.\n\nResponses are required for all questions unless specified as optional.Your responses will be saved automatically so you do not need to complete your application in one session.Once you have finished, simply hit Submit.\n\nIf you have any questions, please direct them to [${application?.siteEmail}](mailto:${application?.siteEmail}).`;
	});

	public tellUsAboutYou = computed<MobileListWithSubItem[]>(() => ([{
		icon: this.applicationService.aboutYouComplete() ? 'fa-circle-check color-green' : 'fa-circle color-lightgray',
		iconStyle: 'fa-solid',
		callback: () => {
			this.applicationService.navigateTo({ step: 'you' })
		},
		text: 'Tell us about You'
	}]));


	public listItems = computed(() => {
		const items: MobileListWithSubItem[] = [];

		const tellUsAboutBusiness: MobileListWithSubItem = {
			icon: this.applicationService.aboutBusinessComplete() ? 'fa-circle-check color-green' : 'fa-circle color-lightgray',
			iconStyle: 'fa-solid',
			callback: () => {
				this.applicationService.navigateTo({ step: 'your-business' })
			},
			text: 'Tell us about Your Business'
		};


		const tellUsAboutTeam: MobileListWithSubItem = {
			icon: this.applicationService.aboutTeamComplete() ? 'fa-circle-check color-green' : 'fa-circle color-lightgray',
			iconStyle: 'fa-solid',
			callback: () => {
				this.applicationService.navigateTo({ step: 'your-team' })
			},
			text: 'Tell us about Your Team'
		};

		const applicationQuestions: MobileListWithSubItem = {
			icon: this.applicationService.answersComplete() ? 'fa-circle-check color-green' : 'fa-circle color-lightgray',
			iconStyle: 'fa-solid',
			text: 'Application Questions',
			subItems: this.applicationService.answers().map((answer, idx) => {
				return {
					icon: answer.answer ? 'fa-circle-check color-green' : 'fa-circle color-lightgray',
					iconStyle: 'fa-solid',
					text: `Question ${idx + 1}`,
					callback: () => {
						this.applicationService.navigateTo({ step: 'questions', questionId: answer.question.questionId });
					}
				};

			}),
		};

		items.push(tellUsAboutBusiness, ...this.tellUsAboutYou(), tellUsAboutTeam, applicationQuestions);

		return items;

	});



	public primaryBtn = computed<string>(() => {

		const application = this.applicationService.application();
		if (application && application.canSubmit && this.applicationService.stepsCompleted() === 4) {
			return 'submit';
		}

		const isClicked = this.instructionClicked();
		if (!isClicked) return 'instructions';
		return '';
	});

	instructionClicked = signal(false);


	constructor() {
		this.applicationService.setApplicationIdAndQuestion(+this.route.snapshot.params['applicationId'], undefined);
		this.frame.setUrlMetadata({ url: `/applications/${this.applicationService.applicationId()}`, pageName: 'Application', backUrl: '/applications' });
	}

	ngOnInit() {
		const applicationId = this.applicationService.applicationId();
		const key = `application-${applicationId}`;
		const isClicked = this.applicationService.getInstructionKey(key);
		this.instructionClicked.set(isClicked);
	}


	openInstructions() {
		const applicationId = this.applicationService.applicationId();
		if (applicationId) {
			const key = `application-${applicationId}`;

			const stored = this.applicationService.getInstructionKey(key);
			if (!stored) {
				this.applicationService.setInstructionKey(key, 'clicked');
				this.instructionClicked.set(true);
			}
			this.applicationService.openInstructionFlyout(this.instructions());
		}

	}

}