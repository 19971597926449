import { Component, OnInit, computed, input } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonFieldWidgetBase } from '../common-field-widget-base';
import { CommonUrlField } from '../field';

@Component({
	selector: 'common-url-field',
	imports: [FormsModule],
	standalone: true,
	templateUrl: './common-url-field.widget.html',
})
export class CommonUrlFieldWidget extends CommonFieldWidgetBase<string, CommonUrlField> implements OnInit {

	public readonly field = input.required<CommonUrlField>();


	private readonly labels = computed(() => {
		const config = this.field().config();

		return config.isSpanish ? {
			missingRequired: 'Spanish Required',
			notValid: 'Spanish invalid url',
		} : {
			missingRequired: `Required field`,
			notValid: `Invalid URL (please include http:// or https://)`,
		};

	});


	public readonly renderedModel = computed(() => {
		const value = this.field().pendingValue() ?? this.field().actualValue();
		return this.render(value);
	});


	private urlPattern = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i;


	ngOnInit() {
		this.field().error.set(this.validate());
	}


	public onChange(value: string) {
		this.field().pendingValue.set(value);
	}


	public async onBlur() {
		this.blur(this.field());
	}


	protected override cleanValue(value: string) {
		return value.trim();
	}


	protected validate(): string {

		const value = (this.getValue(this.field())).trim();
		const config = this.field().config();
		const labels = this.labels();

		if (value.length == 0 && config.required) return labels.missingRequired;
		if (value.length && !value.match(this.urlPattern)) return labels.notValid;

		return '';
	}
}