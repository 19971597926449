import { Injectable, computed, inject, signal } from "@angular/core";
import { CommonDateTimeField, CommonDateTimeFieldConfig, CommonMultiSelectField, CommonOptionsFieldConfig, CommonSelectField, CommonTextField, UrlService, UtilityService } from "@eforall/common";
import { AppService, FuncService } from '../../../services';

@Injectable({ providedIn: 'root' })
export class ProfileDemographicsFormService {

	private readonly app = inject(AppService);
	private readonly func = inject(FuncService);
	private readonly urlService = inject(UrlService);
	private readonly util = inject(UtilityService);


	readonly birthday: CommonDateTimeField = {
		config: signal<CommonDateTimeFieldConfig>({ label: 'Birthday', required: false, isSpanish: this.urlService.isSpanish(), type: 'birthday', includeTime: false }),
		actualValue: computed(() => {
			const user = this.app.data().user;
			const birthdayUTC = user && user.birthDay && user.birthYear ? new Date(
				user.birthYear,				// year e.g. 1900
				Math.floor(user.birthDay / 100) - 1,	// zero-based month e.g. 11 for December
				user.birthDay % 100,			// day of month
				12, 0, 0, 0					// noon
			).getTime() / 1000 : undefined;
			return birthdayUTC;
		}),
		pendingValue: signal<number | undefined | null>(null),
		saving: signal(false),
		save: async (value: number | undefined) => {
			const data = this.app.data().user;
			// 	await this.func.profile.form.setGenderOther({ birthday: value });
			// 	this.applicationService.app.setUserData(data.user);

		},
		error: signal(''),
	};



	private readonly gendersOptions = [
		{ value: 'F', text: 'Female' },
		{ value: 'M', text: 'Male' },
		{ value: 'N', text: 'Non-Binary' },
		{ value: 'O', text: 'Other' },
	];

	readonly gender: CommonSelectField<string> = {
		config: signal<CommonOptionsFieldConfig<string>>({ label: 'Gender', options: this.gendersOptions, required: false, isSpanish: this.urlService.isSpanish() }),
		actualValue: computed(() => this.app.data().user.gender || ''),
		pendingValue: signal<string | null>(null),
		saving: signal(false),
		save: async (value: string) => {
			const user = this.app.data().user;
			await this.func.profile.form.setGender({ gender: value });
			this.app.setUserData({ ...user, gender: value });
		},
		error: signal(''),
	};


	readonly genderOther: CommonTextField = {
		config: signal({ label: 'Other Gender', min: 0, max: 45, fixCase: true, multiLine: false, isSpanish: this.urlService.isSpanish() }),
		actualValue: computed(() => this.app.data().user.genderOther || ''),
		pendingValue: signal<string | null>(null),
		saving: signal(false),
		save: async (value: string) => {
			const user = this.app.data().user;
			if (user.gender === 'O') {
				await this.func.profile.form.setGender({ gender: value });
				this.app.setUserData({ ...user, gender: value });
			}
		},
		error: signal(''),
	};

	private readonly racesOptions = [
		{ value: 'N', text: 'American Indian or Alaska Native' },
		{ value: 'A', text: 'Asian' },
		{ value: 'B', text: 'Black or African American' },
		{ value: 'P', text: 'Native Hawaiian or Other Pacific Islander' },
		{ value: 'W', text: 'White' },
	];

	readonly races: CommonMultiSelectField<string> = {
		config: signal<CommonOptionsFieldConfig<string>>({ label: 'Race', options: this.racesOptions, required: false, isSpanish: this.urlService.isSpanish() }),
		actualValue: computed(() => this.app.data().user.races || []),
		pendingValue: signal<string[] | null>(null),
		saving: signal(false),
		save: async (value: string[]) => {
			const user = this.app.data().user;
			await this.func.profile.form.setRaces({ races: value });
			this.app.setUserData({ ...user, races: value });
		},
		error: signal(''),
	};


	private readonly hispanicOptions = [
		{ value: 'Y', text: 'I am Hispanic or Latino' },
		{ value: 'N', text: 'I am NOT Hispanic or Latino' }
	];
	readonly hispanicOrLatino: CommonSelectField<string> = {
		config: signal<CommonOptionsFieldConfig<string>>({ label: 'Hispanic Or Latino', options: this.hispanicOptions, required: false, isSpanish: this.urlService.isSpanish() }),
		actualValue: computed(() => this.app.data().user.hispanic || ''),
		pendingValue: signal<string | null>(null),
		saving: signal(false),
		save: async (value: string) => {
			const pendingValue = value as 'N' | 'Y';
			const user = this.app.data().user;
			await this.func.profile.form.setHispanic({ hispanic: pendingValue });
			this.app.setUserData({ ...user, hispanic: pendingValue });
		},
		error: signal(''),
	};


	private readonly veteranOptions = [
		{ value: 'Y', text: 'I am a U.S. Veteran' },
		{ value: 'N', text: 'I am NOT a U.S. Veteran' }
	];
	readonly veteran: CommonSelectField<string> = {
		config: signal<CommonOptionsFieldConfig<string>>({ label: 'Veteran', options: this.veteranOptions, required: false, isSpanish: this.urlService.isSpanish() }),
		actualValue: computed(() => this.app.data().user.veteran || ''),
		pendingValue: signal<string | null>(null),
		saving: signal(false),
		save: async (value: string) => {
			const pendingValue = value as 'N' | 'Y';
			const user = this.app.data().user;
			await this.func.profile.form.setVeteran({ veteran: pendingValue });
			this.app.setUserData({ ...user, veteran: pendingValue });
		},
		error: signal(''),
	};


	private readonly immigrantOptions = [
		{ value: 'Y', text: 'I immigrated to the U.S.' },
		{ value: 'N', text: 'I am NOT an immigrant to the U.S.' }
	];
	readonly immigrant: CommonSelectField<string> = {
		config: signal<CommonOptionsFieldConfig<string>>({ label: 'Immigrant', options: this.immigrantOptions, required: false, isSpanish: this.urlService.isSpanish() }),
		actualValue: computed(() => this.app.data().user.immigrant || ''),
		pendingValue: signal<string | null>(null),
		saving: signal(false),
		save: async (value: string) => {
			const pendingValue = value as 'N' | 'Y';
			const user = this.app.data().user;
			await this.func.profile.form.setImmigrant({ immigrant: pendingValue });
			this.app.setUserData({ ...user, immigrant: pendingValue });
		},
		error: signal(''),
	};



	private readonly nativeEnglishOptions = [
		{ value: 'Y', text: 'I am a Native English Speaker' },
		{ value: 'N', text: 'I am NOT a Native English Speaker' }
	];
	readonly nativeEnglish: CommonSelectField<string> = {
		config: signal<CommonOptionsFieldConfig<string>>({ label: 'Native English Speaker', options: this.nativeEnglishOptions, required: false, isSpanish: this.urlService.isSpanish() }),
		actualValue: computed(() => this.app.data().user.nativeEnglish || ''),
		pendingValue: signal<string | null>(null),
		saving: signal(false),
		save: async (value: string) => {
			const pendingValue = value as 'N' | 'Y';
			const user = this.app.data().user;
			await this.func.profile.form.setNativeEnglish({ nativeEnglish: pendingValue });
			this.app.setUserData({ ...user, nativeEnglish: pendingValue });
		},
		error: signal(''),
	};


	private readonly nativeSpanishOptions = [
		{ value: 'Y', text: 'I am a native Spanish Speaker' },
		{ value: 'N', text: 'I am NOT a native Spanish Speaker' }
	];
	readonly nativeSpanish: CommonSelectField<string> = {
		config: signal<CommonOptionsFieldConfig<string>>({ label: 'Native Spanish Speaker', options: this.nativeSpanishOptions, required: false, isSpanish: this.urlService.isSpanish() }),
		actualValue: computed(() => this.app.data().user.nativeSpanish || ''),
		pendingValue: signal<string | null>(null),
		saving: signal(false),
		save: async (value: string) => {
			const pendingValue = value as 'N' | 'Y';
			const user = this.app.data().user;
			await this.func.profile.form.setNativeSpanish({ nativeSpanish: pendingValue });
			this.app.setUserData({ ...user, nativeSpanish: pendingValue });
		},
		error: signal(''),
	};

}