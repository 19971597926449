@if(event(); as event)
{

<mobile-flyout-frame (closed)="close()">

	<mobile-flyout-header-part>
		<common-icon-text iconStyle='fa-solid' icon="fa-info-circle" text="Event Details" spacing="large"
			iconSize="large">
		</common-icon-text>
	</mobile-flyout-header-part>


	<mobile-flyout-body-part>
		<div class="white-box">
			<h4>{{event.eventType}}</h4>
			<div>Starts: {{getDate(event.startUTC)}}</div>
			<div>Ends: {{getDate(event.endUTC)}}</div>

			@if(event.venue){
			<div class="d-flex flex-row gap-2">
				Venue:
				<div>{{event.venue.displayedName}}
					@if(event.venue.placeId){
					<a [href]='venueLink' target="_blank">
						Map
					</a>
					}
					@if(event.venue.website){
					<a [href]="event.venue.website" target="_blank">
						Website
					</a>
					}
				</div>
			</div>
			}
			@else {
			<div>Venue: Online</div>
			}

			@if(event.meetingLink){
			<div>Event Link: <a [href]="event.meetingLink"
					target="_blank">{{util.url.getUrlDomain(event.meetingLink)}}</a>
			</div>
			}

			@if(event.publicNotes){
			<div>Notes: {{event.publicNotes}} </div>
			}
		</div>



		@if(sessionWithTopic(); as accSession){

		<mobile-list [items]="accSession.surveyItem" [height]="100"></mobile-list>


		<h3>{{accSession.topics.length}} Topic(s)</h3>

		@if(!accSession.topics.length){
		<common-message alertType='warning' [closeable]="false">
			{{labels.sessionNoTopicsMsg()}}
		</common-message>
		}
		@else {

		<div ngbAccordion [closeOthers]="true">
			@for (topic of accSession.topics; track $index;) {
			<div ngbAccordionItem>
				<div ngbAccordionHeader>
					<button ngbAccordionButton class="btn btn-flat p-2">
						{{topic.topicName}}
					</button>
				</div>

				<div ngbAccordionCollapse>
					<div ngbAccordionBody class="p-2">
						<ng-template>
							<p>{{topic.topicGoals}}</p>


							<h4>Materials:</h4>

							@if(!topic.hasMaterials){
							<i class="color-gray">
								{{labels.sessionTopicNoMaterialsMsg()}}
							</i>
							}


							@if(topic.hasMaterials && !topic.topicMaterials.length){
							<div>
								{{labels.mdNoMaterialsbefore48hoursMsg()}} {{topic.materialAvailableDate}}.
							</div>
							}


							@if(topic.topicMaterials.length){

							<table class="table table-sm table-bordered">
								@for(material of topic.topicMaterials; track material.id;){
								<tr>
									<td class="p-1">{{material.id}}:</td>
									<td class="p-1"><i class="fa-fw {{material.icon}}"></i></td>
									<td class="p-1"><a [href]="material.url" target="_blank">
											{{material.name}}
										</a>
									</td>
								</tr>
								}
							</table>
							}
						</ng-template>
					</div>
				</div>
			</div>
			}

		</div>

		}
		}


	</mobile-flyout-body-part>
</mobile-flyout-frame>
}