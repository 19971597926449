import { Component, computed, inject } from '@angular/core';
import { CommonButtonColumnWidget, UrlService } from '@eforall/common';
import { MobilePagePart } from '@eforall/mobile';
import { User, LanguageId } from '@interfaces';
import { DomainDataService } from '../../../../services';
import { ApplicationService } from '../application.service';

@Component({
	selector: 'application-readonly-part',
	standalone: true,
	imports: [
		MobilePagePart,
		CommonButtonColumnWidget,
	],
	templateUrl: './application-readonly.part.html',
	styleUrl: './application-readonly.part.scss'
})

export class ApplicationReadonlyPart {
	public applicationService = inject(ApplicationService);
	private domainDataService = inject(DomainDataService);
	private urlService = inject(UrlService);


	public programDetails = computed(() => {
		const application = this.applicationService.application();

		return {
			
			program: application?.name || '',
			deadline: application?.deadline || '',
			programLanguage: application?.application.languageId === LanguageId.Spanish ? 'Espanol' : 'English',
			contact: application?.application.siteEmail,
			hasFeedback: application?.hasFeedback,
		}
	});

	private readonly gendersOptionsMap: { [index: string]: string } = { '': '', 'F': 'Female', 'M': 'Male', 'N': 'Non-Binary', 'O': 'Other', };
	private readonly racesOptionsMap: { [index: string]: string } = { 'N': 'American Indian or Alaska Native', 'A': 'Asian', 'B': 'Black or African American', 'P': 'Native Hawaiian or Other Pacific Islander', 'W': 'White', };
	private readonly employmentStatusOptionsMap: { [index: number]: string } = { 0: '', 1: 'Unemployed', 2: 'Underemployed', 3: 'Employed', 4: 'Retired', };

	public readonly aboutYouQuestions = computed<{ question: string, answer: string }[]>(() => {
		const data: { question: string, answer: string }[] = [];
		const application = this.applicationService.application();
		const user = this.applicationService.app.data().user;
		const participant = application!.participants.find(participant => participant.personId == user.personId);

		if (application && participant) {
			const birthdayUTC = user.birthDay && user.birthYear ? new Date(
				user.birthYear,				// year e.g. 1900
				Math.floor(user.birthDay / 100) - 1,	// zero-based month e.g. 11 for December
				user.birthDay % 100,			// day of month
				12, 0, 0, 0					// noon
			).getTime() / 1000 : undefined;
			data.push(
				{ question: 'Involvement', answer: !participant.doingItFullTime ? '' : participant.doingItFullTime === 'Y' ? 'I work on this business full-time' : 'I work on this business part-time' },
				{ question: 'Your Title', answer: participant.title || '' },
				{ question: 'First Name', answer: user.firstName || '' },
				{ question: 'Middle Initial', answer: user.middleInit || '' },
				{ question: 'Last Name', answer: user.lastName || '' },
				{ question: 'Birthday', answer: birthdayUTC ? this.applicationService.util.date.formatUTC(birthdayUTC, 'MMM D, YYYY', 'No Time', this.urlService.isSpanish() ? 'es-US' : 'en-US') : '' },
				...this.getGenderQuestions(user),
				this.getRaces(user),
				{ question: 'Hispanic Or Latino', answer: !user.hispanic ? '' : user.hispanic === 'Y' ? 'I am Hispanic or Latino' : 'I am NOT Hispanic or Latino' },
				{ question: 'Veteran', answer: !user.veteran ? '' : user.veteran === 'Y' ? 'I am a U.S. Veteran' : 'I am NOT a U.S. Veteran' },
				{ question: 'Immigrant', answer: !user.immigrant ? '' : user.immigrant === 'Y' ? 'I immigrated to the U.S.' : 'I am NOT an immigrant to the U.S.' },
				{ question: 'Native English Speaker', answer: !user.nativeEnglish ? '' : user.nativeEnglish === 'Y' ? 'I am a Native English Speaker' : 'I am NOT a Native English Speaker' },
				{ question: 'Native Spanish Speaker', answer: !user.nativeSpanish ? '' : user.nativeSpanish === 'Y' ? 'I am a Native Spanish Speaker' : 'I am NOT a Native Spanish Speaker' },
				{ question: 'Employement Status', answer: this.employmentStatusOptionsMap[participant.employmentStatusId ?? 0] || '' },
				{ question: 'Head of Household (according to IRS)', answer: !participant.headOfHousehold ? '' : participant.headOfHousehold === 'Y' ? 'I am Head of Household' : 'I am NOT Head of Household' },
				{ question: 'Household Income (last year)', answer: (participant.householdIncome ?? '').toString() },
				{ question: 'Phone', answer: user.phone ? this.applicationService.util.phone.format(user.phone) : '' },
				{ question: 'Address', answer: user.zipId ? this.applicationService.util.zip.format(user.zipId) : '' },
			);
		}

		return data;
	});


	private getGenderQuestions(user: User) {
		const genderQuestions = [{ question: 'Gender', answer: this.gendersOptionsMap[user.gender ?? ''] || '' }];
		if (user.gender && user.gender === 'O') genderQuestions.push({ question: 'Other Gender', answer: user.genderOther || '' });

		return genderQuestions;
	}

	private getRaces(user: User) {
		const answer = user.races?.map(race => this.racesOptionsMap[race]).join(', ');
		return { question: 'Race', answer: answer || '' };

	}


	public readonly businessQuestions = computed<{ question: string, answer: string }[]>(() => {
		const data: { question: string, answer: string }[] = [];
		const application = this.applicationService.application();
		const user = this.applicationService.app.data().user;
		if (application) {
			data.push(
				{ question: 'Business Name', answer: application.application.companyName },
				{ question: 'Business Type', answer: application.application.companyTypeId ? this.domainDataService.data().companyTypeMap[application.application.companyTypeId]?.label || '' : '' },
				{ question: 'Industry', answer: application.business.industryId ? this.domainDataService.data().industryMap[application.business.industryId]?.label || '' : '' },
				{ question: 'Offering', answer: application.business.offerings },
				{ question: 'Annual Revenue (last year)', answer: (application.application.annualRevenue ?? '').toString() },
				{ question: 'Number of Full-Time Employees (include owners)', answer: (application.application.numFullTimeEmployees ?? '').toString() },
				{ question: 'Number of Part-Time Employees (include owners)', answer: (application.application.numPartTimeEmployees ?? '').toString() },
				{ question: 'Number of Contractors (IRS form 1099)', answer: (application.application.numContractors ?? '').toString() },
				{ question: 'Business Phone', answer: application.business.phone ? this.applicationService.util.phone.format(application.business.phone) : '' },
				{ question: 'Business Address', answer: application.business.zipId ? this.applicationService.util.zip.format(application.business.zipId) : '' },
			);
		}

		return data;
	});

	public readonly answersQuestions = computed<{ question: string, answer: string }[]>(() => {
		const answers = this.applicationService.answers();
		return answers.reduce((a: { question: string, answer: string }[], answer) => {
			a.push({ question: answer.question.text, answer: answer.answer || '' });
			return a;
		}, []);

	});


	navigateToFeedback() {
		const application = this.applicationService.application();

		if (application) {
			const key = application.application.accId ? `acc-${application.application.accId}` : `pic-${application.application.picId}`;
			this.applicationService.router.navigate(['feedback'], { queryParams: { key } });
		}
	}


}