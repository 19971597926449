import { Component, computed, inject, OnInit, signal } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { CommonButtonRowWidget } from '@eforall/common';
import { MobileFrameService, MobilePagePart } from '@eforall/mobile';
import { Feedback } from '@interfaces';
import { FuncService } from '../../services';
import { FeedbackLabelsService } from './feedback-labels.service';

@Component({
	selector: 'feedback-page',
	standalone: true,
	imports: [
		MobilePagePart,
		RouterLink,
		CommonButtonRowWidget,
		FormsModule,
	],
	templateUrl: './feedback.page.html'
})

export class FeedbackPage implements OnInit {
	private frame = inject(MobileFrameService);
	private func = inject(FuncService);
	public labels = inject(FeedbackLabelsService);
	private route = inject(ActivatedRoute);
	private router = inject(Router);

	public feedback = signal<Feedback[]>([]);
	public selectedFeedback = signal<Feedback | undefined>(undefined);

	public feedbackInfo = computed(() => {
		const feedback = this.feedback();
		return feedback.length ? `${feedback.length} Program${feedback.length != 1 ? 's' : ''} with Feedback` : 'No Feedback Available';
	});


	async ngOnInit() {
		const feedback = await this.func.applications.getFeedback();
		this.feedback.set(feedback);

		this.frame.setUrlMetadata({ url: `/feedback`, pageName: 'Feedback', backUrl: `/` });
		for (const data of this.feedback()) {
			const pageName = `Feedback-${data.siteName}-${data.programInstanceName}-${data.programName}`;
			const url = `/feedback?key=${data.key}`;
			this.frame.setUrlMetadata({ url, pageName, header: 'Feedback', backUrl: `/` });
		}


		const key: string = this.route.snapshot.queryParams['key'];
		if (key) {
			this.setSelectedFeedback(key);
		}

	}


	onChange(value: string) {
		this.router.navigate(['feedback'], { queryParams: { key: value } });
		this.setSelectedFeedback(value);
	}

	setSelectedFeedback(key:string) {
		const feedback = this.feedback().find(pgm => pgm.key == key);
		this.selectedFeedback.set(feedback);
	}

}