<mobile-page-part>

	<div class="info-content">
		<h3>Programs</h3>
	</div>

	@for(program of programsList(); track $index;){
	<div class="d-flex flex-column gap-2">
		<h3>{{program.header}}</h3>
		<div>{{program.blob}}</div>
		<common-button-row [center]="true">
			<a class="btn btn-standard" [target]="program.header" [href]="program.url">{{learnMoreLabel()}}</a>
		</common-button-row>
	</div>
	}

</mobile-page-part>