import { Component, computed, inject, OnInit, signal } from '@angular/core';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { CommonAddressFieldWidget, CommonButtonColumnWidget, CommonButtonRowWidget, CommonDateTimeFieldWidget, CommonMultiselectFieldWidget, CommonNumberFieldWidget, CommonPhoneFieldWidget, CommonSelectFieldWidget, CommonTextFieldWidget } from '@eforall/common';
import { MobileFrameService, MobilePagePart } from '@eforall/mobile';
import { FlyoutService, FuncService } from '../../../../services';
import { ApplicationJoinCodeFormService } from './join-code-form.service';


@Component({
	selector: 'applications-join-code-page',
	standalone: true,
	imports: [
		MobilePagePart,
		CommonTextFieldWidget,
		CommonButtonRowWidget,
		CommonSelectFieldWidget,
		CommonNumberFieldWidget,
		CommonPhoneFieldWidget,
		CommonAddressFieldWidget,
		CommonMultiselectFieldWidget,
		CommonDateTimeFieldWidget,
		CommonButtonColumnWidget,
		RouterLink
	],
	templateUrl: './join-code.page.html'
})

export class ApplicationJoinCodePage implements OnInit {
	private frame = inject(MobileFrameService);
	private func = inject(FuncService);
	private route = inject(ActivatedRoute);

	public form = inject(ApplicationJoinCodeFormService);


	public readonly code = signal('');
	public readonly codeDetails = signal<{ applicationId: number, programName: string, applicantName: string, companyName: string, applicationOpen: boolean } | undefined>(undefined);

	public join = false;

	public readonly showOtherGender = computed(() => {
		if (this.form.gender.actualValue() === 'O') return true;
		else return false;
	});

	public instructions = computed(() => 'Please tell us about you. We __promise__ never to share your individual information without your authorization. We compile bulk statistics (no individually identifiable data) to demonstrate the demographic information of the people with whom we support. This information is frequently used in our grant applications and donation solicitations which allows us to offer our programs to aspiring entrepreneurs like you.');


	async ngOnInit() {
		this.code.set(this.route.snapshot.params['applCode']);
		this.frame.setUrlMetadata({ url: `/applications/join/${this.code}`, pageName: 'Join', backUrl: '/applications' });

		this.codeDetails.set(await this.func.pendingParticipant.validateInviteCode({ code: this.code().split('-').join('') }));

	}


}