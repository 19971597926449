import { Component, inject } from '@angular/core';
import { RouterLink } from '@angular/router';
import { MobileFrameService, MobilePagePart } from '@eforall/mobile';
import { CommonMessageWidget } from '@eforall/common';
import { NgbAccordionModule } from '@ng-bootstrap/ng-bootstrap';

@Component({
	selector: 'example-text-page',
	standalone: true,
	imports: [
		MobilePagePart,
		RouterLink,
		CommonMessageWidget,
		NgbAccordionModule,
	],
	templateUrl: './text.page.html'
})

export class ExampleTextPage {
	private frame = inject(MobileFrameService);
	items = ['First', 'Second', 'Third'];


	constructor() {
		this.frame.setUrlMetadata({ url: '/examples/text', pageName: 'Text', backUrl: '/examples' });
	}
}