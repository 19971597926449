import { Component, inject } from '@angular/core';
import { RouterLink } from '@angular/router';
import { MobileFrameService, MobilePagePart } from '@eforall/mobile';
import { CommonButtonColumnWidget, CommonButtonRowWidget } from '@eforall/common';

@Component({
	selector: 'buttons-page',
	standalone: true,
	imports: [
		MobilePagePart,
		RouterLink,
		CommonButtonColumnWidget,
		CommonButtonRowWidget,
	],
	templateUrl: './buttons.page.html'
})

export class ExampleButtonsPage {
	private frame = inject(MobileFrameService);

	constructor() {
		this.frame.setUrlMetadata({ url: '/examples/buttons', pageName: 'Buttons', backUrl: '/examples' });
	}
}