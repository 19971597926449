import { Component, computed, inject } from '@angular/core';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { MobileFrameService, MobileListWidget, MobileListWithSubItem, MobilePagePart } from '@eforall/mobile';
import { ApplicationStatusId } from '@interfaces';
import { AppService } from '../../services';
import { CommonButtonColumnWidget } from '@eforall/common';
const OPEN_STATUS = [ApplicationStatusId.ReadPending, ApplicationStatusId.InterviewPending, ApplicationStatusId.SelectPending, ApplicationStatusId.TeamPending];

@Component({
	selector: 'applications-page',
	standalone: true,
	imports: [
		MobilePagePart,
		CommonButtonColumnWidget,
		MobileListWidget,
		RouterLink,
	],
	templateUrl: './applications.page.html'
})

export class ApplicationsPage {
	private frame = inject(MobileFrameService);
	public app = inject(AppService);
	private router = inject(Router);
	private route = inject(ActivatedRoute);

	public applicationsList = computed<MobileListWithSubItem[]>(() => {
		return this.app.data().userApplications.reduce((a: MobileListWithSubItem[], appl) => {
			a.push({
				icon: 'fa-file-edit',
				iconStyle: 'fa-regular',
				text: `${appl.application.siteName} ${appl.application.programInstance} ${appl.application.programName}`,
				subText: this.getApplicationStatus(appl.application.applicationStatusId),
				callback: () => {
					this.navigateToApplication(appl.application.applicationId)
				}
			});

			return a;
		}, [])
	});

	public readonly applicationInfoContent = computed(() => {
		const applications = this.applicationsList();

		const pendingAppls = applications.filter(appl => appl.subText?.endsWith('Pending'));
		if (pendingAppls.length) return `${pendingAppls.length} Pending Application${pendingAppls.length > 1 ? 's' : ''}`;

		const priorAppls = applications.filter(appl => appl.subText?.endsWith('Completed'));
		if (priorAppls.length) return `${priorAppls.length} Prior Application${priorAppls.length > 1 ? 's' : ''}`;

		return `Start an Application`;
	});

	constructor() {
		this.frame.setUrlMetadata({ url: '/applications', pageName: 'Applications', backUrl: '/' });
	}


	public navigateToApplication(applicationId: number) {
		this.router.navigate([applicationId], { relativeTo: this.route });
	}

	private getApplicationStatus(applicationStatusId: ApplicationStatusId) {
		return applicationStatusId == ApplicationStatusId.AcceptPending ? 'Pending' :
			OPEN_STATUS.includes(applicationStatusId) ? 'Open' : 'Completed';
	}
}
