@if(frame.config(); as config)
{

<mobile-flyout-frame (closed)="close()">

	<mobile-flyout-header-part>
		<common-icon-text iconStyle='fa-solid' icon="fa-info-circle" text="Application Changes" spacing="large"
			iconSize="large">
		</common-icon-text>
	</mobile-flyout-header-part>


	<mobile-flyout-body-part>
		<div>
			TODO: Message will be added soon.<br>
			Talk about changing the status to pending for edits, must re-submit still.
			Also explain Withdraw and provide an option to do so.

			@if(applicationService.application(); as application){
				@if(application.canReopen)
				{
				<button class="btn btn-primary" (click)="reopen()">Repoen</button>
				}
				@if(application.canWithdraw)
				{
				<button class="btn btn-danger" (click)="withdraw()">Withdraw</button>
				}
			}
		</div>


	</mobile-flyout-body-part>
</mobile-flyout-frame>
}