@if(participant(); as participant)
{

<mobile-flyout-frame (closed)="close()">

	<mobile-flyout-header-part>
		<common-icon-text iconStyle='fa-solid' icon="fa-info-circle"
			text="{{participant.firstName}} {{participant.lastName}}" spacing="large" iconSize="large">
		</common-icon-text>
	</mobile-flyout-header-part>


	<mobile-flyout-body-part>
		@if(!remove() && !copyLink())
		{
		<div>
			<h4>Instructions and an invite link has been emailed to {{participant.email}}. If you would like to resend
				that email or copy the link and send it yourself, use the buttons below.</h4>
			@if(participant.code)
			{
			<div>
				Invite Code: {{applicationService.util.text.formatCode(participant.code)}}
			</div>
			}
			<div>
				Email: {{participant.email}}
			</div>
			<div>
				Title: {{participant.title}}
			</div>


		</div>
		<common-button-column>

			<common-button-row [center]="true">
				@if(participant.status === 'Pending'){
				<button class="btn btn-primary" (click)="resendInvite()">Resend Invite</button>
				}
				<button class="btn btn-standard" (click)="remove.set(true)">Remove</button>
			</common-button-row>
			<button class="btn btn-standard" (click)="copyToClipboard()">Copy Link</button>

		</common-button-column>
		}
		@else if(remove()) {
		<h4>{{removeInfo()}}</h4>
		<common-button-column>
			<button class="btn btn-danger btn-large" (click)="removeParticipant()">Yes, Remove</button>
			<button class="btn btn-standard btn-large" (click)="remove.set(false)">Cancel</button>
		</common-button-column>
		}
		@else if(copyLink()){
		<h4>The invite link has been copied to your clipboard. Please send it to {{participant.firstName}} {{participant.lastName}}</h4>
		<common-button-column>
			<button class="btn btn-standard btn-large" (click)="copyLink.set(false)">Okay</button>
		</common-button-column>
		}
	</mobile-flyout-body-part>
</mobile-flyout-frame>
}