<mobile-page-part>

	<div class="info-content">
		<h3>Examples</h3>
	</div>

	<common-button-row>
		<button class="btn btn-standard" routerLink="fields">Fields</button>
		<button class="btn btn-standard" routerLink="text">Text</button>
		<button class="btn btn-standard" routerLink="buttons">Buttons</button>
		<button class="btn btn-standard" (click)="endlessSpinner()">Endless Spinner</button>
	</common-button-row>



	<common-nps-selector key="abc" [value]="0" (updateValue)="updateValue($event)"></common-nps-selector>
	
	
	<div class="white-box">
		<common-nps-selector key="def" [value]="0" (updateValue)="updateValue($event)"></common-nps-selector>
	</div>
	
	<div class="white-box">
		<common-nps-selector key="ghi" [value]="0" (updateValue)="updateValue($event)"></common-nps-selector>
	</div>


</mobile-page-part>