import { Injectable, computed } from "@angular/core";
import { BaseLabelService } from "../../services";

@Injectable({ providedIn: 'root' })
export class CalendarLabelsService extends BaseLabelService {

	feedbackOverview = computed(() => this.getLabel([
		'Below you will find feedback provided to you and the role of the person that provided it. Keep in mind that the feedback is from volunteers which come from many different backgrounds and points of view. We hope you’ll find this feedback helpful in your business growth and/or it gives you insight on the way an outsider perceives and understands your business. Feel free to reach out to the program manager with any questions.',
		'A continuación, encontrará los comentarios proporcionados a usted y el rol de la persona que los proporcionó. Tenga en cuenta que los comentarios provienen de voluntarios que provienen de diferentes orígenes y puntos de vista. Esperamos que este comentario le resulte útil para el crecimiento de su negocio y / o le brinde una idea de la forma en que un extraño percibe y entiende su negocio. No dude en comunicarse con el gerente del programa si tiene alguna pregunta.',
	]));

	topics = computed(() => this.getLabel(['Topic(s)', 'Tema(s)']));
	materials = computed(() => this.getLabel(['Materials', 'Materiales']));
	sessionNoTopicsMsg = computed(() => this.getLabel(['This session does not have any topics.', 'Esta sesión no tiene ningún tema.']));
	sessionTopicNoMaterialsMsg = computed(() => this.getLabel(['This topic does not have any course material.', 'Este tema no tiene ningún material de curso.']));
	mdNoMaterialsbefore48hoursMsg = computed(() => this.getLabel(['The course materials for this topic will be made available on ', 'Los materiales del curso para este tema estarán disponibles el ']));
	mdSurveyMissedMsg = computed(() => this.getLabel(['You have missed the deadline to complete this survey. The deadline was {{deadline}} and it is now {{now}}.', 'No cumplió con la fecha límite para completar esta encuesta. La fecha límite era el {{deadline}} y ahora es el {{now}}.']));
	surveyCompleteMsg = computed(() => this.getLabel(['Thank you. You have succesfully completed this survey.', 'Gracias. Ha completado con éxito esta encuesta.']));
	mdSurveyNotAvailableMsg = computed(() => this.getLabel(['The Program Manager has not made this survey available to you. If you need assistance, reach out to the Program Manager at {{pm-email}}.', 'El administrador del programa no ha puesto esta encuesta a su disposición. Si necesita ayuda, comuníquese con el gerente del programa en {{pm-email}}.']));
	mdSurveyMarkedAbsentMsg = computed(() => this.getLabel(['You cannot take this survey because you were marked absent. If you need assistance, reach out to the Program Manager at {{pm-email}}.', 'No puede realizar esta encuesta porque se le marcó como ausente. Si necesita ayuda, comuníquese con el gerente del programa en {{pm-email}}.']));
	mdSurveyAvailableMsg = computed(() => this.getLabel(['Take survey! Available for {{available-for}}.', 'Realizar encuesta! Disponible para {{available-for}}.']));
}