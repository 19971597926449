import { Component, inject } from '@angular/core';
import { MobileFrameService, MobilePagePart } from '@eforall/mobile';

@Component({
	selector: 'my-team-page',
	standalone: true,
	imports: [
		MobilePagePart,
	],
	templateUrl: './my-team.page.html'
})

export class CohortMyTeamPage {
	private frame = inject(MobileFrameService);

	constructor() {
		this.frame.setUrlMetadata({ url: '/cohort/my-team', pageName: 'My Team', backUrl: '/cohort' });
	}

}