import { Component, inject, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterLink } from '@angular/router';
import { CommonButtonRowWidget } from '@eforall/common';
import { MobileFrameService, MobileListWidget, MobilePagePart } from '@eforall/mobile';
import { CalendarLabelsService } from './calendar-labels.service';
import { CalendarService } from './calendar.service';

@Component({
	selector: 'calendar-page',
	standalone: true,
	imports: [
		MobilePagePart,
		RouterLink,
		CommonButtonRowWidget,
		MobileListWidget
	],
	templateUrl: './calendar.page.html'
})

export class CalendarPage implements OnInit {
	private frame = inject(MobileFrameService);
	public labels = inject(CalendarLabelsService);
	public calendarService = inject(CalendarService);


	constructor() {
		this.frame.setUrlMetadata({ url: `/calendar`, pageName: 'Calendar', backUrl: `/` });
	}


	async ngOnInit() {
		await this.calendarService.loadData()
	}

}