<mobile-page-part>

	<div class="info-content">
		<h3>Example Buttons</h3>
	</div>


	<h4>Regular buttons</h4>
	<common-button-row>
		<button class="btn btn-primary">Primary</button>
		<button class="btn btn-danger">Warning</button>
		<button class="btn btn-standard">Standard</button>
		<button class="btn btn-standard" disabled>Disabled</button>
	</common-button-row>


	<!-- <h4>Layouts</h4>
	<common-button-row></common-button-row>
	<common-button-column></common-button-column> -->



</mobile-page-part>