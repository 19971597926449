import { Component, OnInit, inject, input } from '@angular/core';
import { RouterLink } from '@angular/router';
import { CommonAddressFieldWidget, CommonButtonColumnWidget, CommonButtonRowWidget, CommonMessageWidget, CommonSelectFieldWidget } from '@eforall/common';
import { MobilePagePart } from '@eforall/mobile';
import { MarkdownModule, provideMarkdown } from 'ngx-markdown';
import { ApplicationApplyService } from './apply.service';
import { ApplicationProgramsPart } from './programs-part/programs.part';
import { ApplicationCompanyPart } from './company-part/company.part';

@Component({
	selector: 'application-apply-part',
	standalone: true,
	imports: [
		ApplicationCompanyPart,
		ApplicationProgramsPart,
		CommonAddressFieldWidget,
		CommonButtonColumnWidget,
		CommonButtonRowWidget,
		CommonMessageWidget,
		CommonSelectFieldWidget,
		MarkdownModule,
		MobilePagePart,
		RouterLink,
	],
	providers: [provideMarkdown()],
	templateUrl: './apply.part.html'
})

export class ApplicationApplyPart implements OnInit {
	public applyService = inject(ApplicationApplyService);

	public infoTextMarkdown = input.required<string>();
	public type = input.required<'Acc' | 'Pic'>();

	ngOnInit(): void {
		this.applyService.onInit(this.type());
	}


}