import { Component, input } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonNetPromoterScoreWidget } from '@eforall/common';
import { SurveyField, SurveyQuestionField } from '@interfaces';

@Component({
	selector: 'survey-fields-display',
	standalone: true,
	imports: [
		FormsModule,
		CommonNetPromoterScoreWidget,
	],
	templateUrl: './fields-display.part.html',
	styleUrls: ['./fields-display.part.scss'],
})
export class SurveyFieldsDisplayPart {

	public surveyFields = input.required<SurveyField[]>();

	enabled: { [index: number]: boolean } = {};


	setEnabled(field: SurveyField, value: boolean) {
		field.enabled = value;
		field.subs?.map(sub => sub.enabled = value);
	}


	updateValue(field: SurveyQuestionField, e: { key: string, value: number }) {
		if (field.key == e.key) field.typeAndValue.value = e.value;
	}


	onInput(field: SurveyQuestionField, e: string) {
		const text = (e ?? '').trim();
		field.typeAndValue.value = text;
	}



}
