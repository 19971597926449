import { Component, inject } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterLink } from '@angular/router';
import { CommonButtonRowWidget } from '@eforall/common';
import { MobileFrameService, MobilePagePart } from '@eforall/mobile';
import { GoalsLabelsService } from './goals-labels.service';

@Component({
	selector: 'goals-page',
	standalone: true,
	imports: [
		MobilePagePart,
		RouterLink,
		CommonButtonRowWidget,
		FormsModule,
	],
	templateUrl: './goals.page.html'
})

export class GoalsPage {
	private frame = inject(MobileFrameService);
	public labels = inject(GoalsLabelsService);

	constructor() {
		this.frame.setUrlMetadata({ url: `/goals`, pageName: 'Goals', backUrl: `/` });
	}

}