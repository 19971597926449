import { Component, computed, inject } from '@angular/core';
import { MobileFrameService, MobilePagePart } from '@eforall/mobile';
import { AppService, FuncService } from '../../../services';

@Component({
	selector: 'profile-emails-page',
	standalone: true,
	imports: [
		MobilePagePart,
	],
	templateUrl: './emails.page.html'
})

export class ProfileEmailsPage {
	private frame = inject(MobileFrameService);
	private app = inject(AppService);
	private func = inject(FuncService);

	public emails = computed(() => {
		const emails = this.app.data().user.emails;
		return { primary: emails.find(email => email.isDefault)?.email, others: emails.filter(email => !email.isDefault) }
	});

	constructor() {
		this.frame.setUrlMetadata({ url: '/profile/emails', pageName: 'Emails', backUrl: '/profile' });
	}


	async setPrimaryEmail(emailId: number) {
		await this.app.update(this.func.email.setPrimary, { emailId });
	}

	async removeEmail(emailId: number) {
		await this.app.update(this.func.email.remove, { emailId });
	}

}