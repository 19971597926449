import { Component, inject } from '@angular/core';
import { RouterLink } from '@angular/router';
import { CommonButtonColumnWidget, CommonButtonRowWidget, CommonMessageWidget } from '@eforall/common';
import { MarkdownModule, provideMarkdown } from 'ngx-markdown';
import { ApplicationApplyService } from '../apply.service';

@Component({
	selector: 'application-programs-part',
	standalone: true,
	imports: [
		MarkdownModule,
		CommonButtonRowWidget,
		RouterLink,
		CommonButtonColumnWidget,
		CommonMessageWidget,
	],
	providers: [provideMarkdown()],
	templateUrl: './programs.part.html'
})

export class ApplicationProgramsPart {
	public applyService = inject(ApplicationApplyService);

}