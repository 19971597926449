import { Component, computed, inject } from '@angular/core';
import { RouterLink } from '@angular/router';
import { CommonButtonRowWidget, UrlService } from '@eforall/common';
import { MobileFrameService, MobilePagePart } from '@eforall/mobile';

@Component({
	selector: 'programs-page',
	standalone: true,
	imports: [
		MobilePagePart,
		RouterLink,
		CommonButtonRowWidget
	],
	templateUrl: './programs.page.html'
})

export class ProgramsPage {
	private frame = inject(MobileFrameService);
	private urlService = inject(UrlService);

	public learnMoreLabel = computed(() => this.urlService.isSpanish() ? 'Aprende más' : 'Learn More');

	public readonly programsList = computed(() => {
		const isSpanish = this.urlService.isSpanish();
		return [
			{
				header: isSpanish ? `Acelerador de Negocios` : `Business Accelerator`,
				blob: isSpanish ? `El Acelerador de Negocios de EparaTodos es un programa gratuito de un año que se ofrece dos veces por año en cada una de nuestras comunidades. Este programa ofrece una combinación única de capacitación y mentoría inmersivas, y acceso a una amplia red profesional. Cualquiera que sueñe con iniciar o desarrollar un negocio u organización sin fines de lucro puede postular.` : `The EforAll Accelerator Program is a free, one-year program offered twice a year in each of our communities. This program offers a unique combination of immersive business training, mentorship, and access to an extended professional network. Anyone with a dream to start or grow a business or nonprofit is encouraged to apply.`,
				url: isSpanish ? 'https://eforall.org/es/acelerador-de-negocios/' : 'https://eforall.org/business-accelerator-program/',
			},
			{
				header: isSpanish ? 'Concursos Lanza Tu Idea' : `Pitch Contests`,
				blob: isSpanish ? `Eventos para la comunidad vertiginosos y divertidos que ayudan a los emprendedores en las etapas iniciales a obtener exposición valiosa y comentarios sobre una idea de negocios. Excelente oportunidad para crear una red empresarial, validar una idea de negocios y competir para ganar un premio en dinero. Los emprendedores pueden postularse sin costa alguno y todos pueden asistir gratis.` : `Fast-paced, fun community events that help early-stage entrepreneurs gain valuable exposure and feedback on a business idea. Great opportunity to network, validate a business idea, and compete to win a cash prize. It is free for entrepreneurs to apply and free for all to attend.`,
				url: isSpanish ? 'https://eforall.org/es/lanza-tu-idea/' : 'https://eforall.org/pitch-contests/',
			},
		]
	});
}