@if(frame.config(); as config)
{

<mobile-flyout-frame (closed)="close()">

	<mobile-flyout-header-part>
	</mobile-flyout-header-part>


	<mobile-flyout-body-part>
		<div class="d-flex flex-column align-items-center gap-2">
			<a class='user-menu'>
				<i class="fa-duotone fa-trophy-star color-gold" style="font-size: 4em;"></i>
				<h3 class="pt-3 text-uppercase">{{config.user.firstName}}</h3>
				<p>{{config.user.lastName}}</p>
			</a>
		</div>


		<div class="menu-items">
			@for(menuItem of menu(); track $index;){
			@if(menuItem.routerLink){
			<a [routerLink]="menuItem.routerLink" (click)="close()">
				<i class="{{menuItem.iconStyle}} {{menuItem.icon}} {{menuItem.iconColor}}"></i>
				<span>{{menuItem.label}}</span>
			</a>
			}
			@if(menuItem.url){
			<a [href]="menuItem.url" [target]='menuItem.label' (click)="close()">
				<i class="{{menuItem.iconStyle}} {{menuItem.icon}} {{menuItem.iconColor}}"></i>
				<span>{{menuItem.label}}</span>
			</a>
			}
			}

			<a (click)="signOut()">
				<i class="fa-duotone fa-left-from-bracket"></i>
				<span>Sign Out</span>
			</a>


			<a routerLink="applications/apply-accelerator" (click)="close()">
				<i class="fa-duotone fa-file-pen"></i>
				<span>Apply Accelerator</span>
			</a>

			<a routerLink="applications/apply-pitch" (click)="close()">
				<i class="fa-duotone fa-file-pen"></i>
				<span>Apply Pitch</span>
			</a>
		</div>
	</mobile-flyout-body-part>
</mobile-flyout-frame>
}