import { Component, inject } from '@angular/core';
import { CommonButtonColumnWidget } from '@eforall/common';
import { MobileFrameService, MobilePagePart } from '@eforall/mobile';

@Component({
	selector: 'home-page',
	standalone: true,
	imports: [
		MobilePagePart,
		CommonButtonColumnWidget,
	],
	templateUrl: './home.page.html'
})

export class HomePage {
	private frame = inject(MobileFrameService);

}