<mobile-page-part>

	<div class="info-content">
		<h3>August 6, 2024</h3>
	</div>

	<div>
		<div class="d-flex flex-column gap-2">
			<div class="white-box">
				<h4>Finish your 2025 Winter Accelerator Application</h4>
				<div>You currently have an application to the Merrimack Valley, MA 2025 Winter Accelerator program that
					is still pending submission. Please complete and submit it before the deadline in 2 days.</div>
				<br>
				<i>Deadline: Aug 8th, 2024</i>
				<common-button-column>
					<button class="btn btn-primary btn-large" routerLink="/applications">Finish and Submit</button>
				</common-button-column>
			</div>

			<div class="white-box">
				<h4>Find a Pitch Contest near you!</h4>
				<div>Fast-paced, fun community events that help early-stage entrepreneurs gain valuable exposure and
					feedback on a business
					idea. Great opportunity to network, validate a business idea, and compete to win a cash prize. It is
					free for
					entrepreneurs to apply and free for all to attend.</div>
				<br>
				<common-button-column>
					<button class="btn btn-standard btn-large" routerLink="/programs">Learn More</button>
				</common-button-column>
			</div>
		</div>
	</div>

</mobile-page-part>