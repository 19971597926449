import { DbDomainData } from '@interfaces';


/**
 * Compare two auth state objects and determine if they are equivalent
 */
export function domainDataEquality(s1: DbDomainData, s2: DbDomainData) {

	if (s1.asOfUtc !== s2.asOfUtc) return false;

	return true;
}