import { computed, inject, Injectable, signal } from "@angular/core";
import { MobileListWithSubItem } from '@eforall/mobile';
import { FlyoutService, FuncService } from "../../services";
import { CalendarEvent, EventTypeId } from "@interfaces";
import { UrlService, UtilityService } from "@eforall/common";

@Injectable({ providedIn: 'root' })
export class CalendarService {

	private func = inject(FuncService);
	public util = inject(UtilityService);
	private urlService = inject(UrlService);
	private flyoutService = inject(FlyoutService);

	private loaded = signal(false);
	public onGoingList = signal<MobileListWithSubItem[]>([]);
	public upcomingList = signal<MobileListWithSubItem[]>([]);
	public pastList = signal<MobileListWithSubItem[]>([]);

	public date = computed(() => this.util.date.formatDate(new Date(), 'MMM D, YYYY', this.urlService.isSpanish() ? 'es-US' : 'en-US'));


	async loadData() {

		if (!this.loaded()) {
			const events = await this.func.calendar.get();

			const currentUTC = Date.now() / 1000;

			const { endOfWeekUTC, startOfWeekUTC } = this.getWeekStartAndEndUTC(currentUTC)

			const onGoingList: MobileListWithSubItem[] = events
				.filter(event =>
					(event.toolStartUTC <= currentUTC && currentUTC <= event.toolEndUTC)
					||
					(event.startUTC <= endOfWeekUTC && event.endUTC >= startOfWeekUTC)
				).sort((a, b) => a.startUTC - b.startUTC)
				.reduce((a: MobileListWithSubItem[], event) => {

					const item = this.getItem(event);
					a.push(item);
					return a;
				}, [])


			this.onGoingList.set(onGoingList);

			const pastList: MobileListWithSubItem[] = events
				.filter(event => event.endUTC < startOfWeekUTC)
				.sort((a, b) => b.startUTC - a.startUTC)
				.reduce((a: MobileListWithSubItem[], event) => {

					const item = this.getItem(event);
					a.push(item);
					return a;
				}, []);

			this.pastList.set(pastList);

			const upcomingList: MobileListWithSubItem[] = events
				.filter(event => event.startUTC > endOfWeekUTC)
				.sort((a, b) => a.startUTC - b.startUTC)
				.reduce((a: MobileListWithSubItem[], event) => {

					const item = this.getItem(event);
					a.push(item);
					return a;
				}, []);

			this.upcomingList.set(upcomingList);

		}

	}


	private getWeekStartAndEndUTC(currentUTC: number): { startOfWeekUTC: number, endOfWeekUTC: number } {
		const now = new Date(currentUTC * 1000);

		const currentDayOfWeek = now.getUTCDay();

		// Calculate the start of the week (Sunday)
		const startOfWeek = new Date(Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate() - currentDayOfWeek));
		const startOfWeekUTC = startOfWeek.getTime() / 1000;

		// Calculate the end of the week (Saturday 23:59:59)
		const endOfWeek = new Date(Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate() + (6 - currentDayOfWeek) + 1, 0, 0, 0));
		const endOfWeekUTC = endOfWeek.getTime() / 1000;

		return {
			startOfWeekUTC,
			endOfWeekUTC
		};
	}


	private getItem(event: CalendarEvent): MobileListWithSubItem {

		let item: MobileListWithSubItem = {
			icon: 'fa-help',
			iconStyle: 'fa-regular',
			text: 'Unknown',
		};

		const text = this.formatEventDates(event.startUTC, event.endUTC, this.urlService.isSpanish() ? 'es-US' : 'en-US');
		const callback = () => { this.flyoutService.showCalendarEvent(event); }

		let iconStyle = 'fa-solid';
		if (event.surveyStatus) {
			const status = event.surveyStatus;
			if (status == 'Complete') {
				item = {
					icon: `fa-circle color-green`,
					iconStyle,
					text,
					subText: `Accelerator Session: Survey Complete`,
					callback,
				}
			}
			if (status == 'Missed' || status == 'Unavailable') item = {
				icon: `fa-circle color-darkred`,
				iconStyle,
				text,
				subText: `Accelerator Session: Survey ${status}`,
				callback,
			};

			if (status == 'NotOpenYet') {
				const topicText = event.countOfTopics ? `: ${event.countOfTopics} Topic${event.countOfTopics == 1 ? '' : 's'}` : ''
				item = {
					icon: `fa-circle color-lightgray`,
					iconStyle,
					text,
					subText: `Accelerator Session${topicText}`,
					callback,
				};
			}
			if (status == 'Open') item = {
				icon: `fa-circle color-eforall-blue`,
				iconStyle,
				text,
				subText: `Accelerator Session: Survey Open`,
				callback,
			};

		}
		else {
			iconStyle = 'fa-regular';
			if (event.eventTypeId == EventTypeId.AccFinalPitch) item = { icon: 'fa-user-chart', iconStyle, text, subText: event.eventType, callback, };
			if (event.eventTypeId == EventTypeId.AccMentorMatching) item = { icon: 'fa-users', iconStyle, text, subText: event.eventType, callback, };
			if (event.eventTypeId == EventTypeId.AccQuarterliesQ2) item = { icon: 'fa-chart-pie-alt', iconStyle, text, subText: event.eventType, callback, };
			if (event.eventTypeId == EventTypeId.AccQuarterliesQ3) item = { icon: 'fa-chart-pie-alt', iconStyle, text, subText: event.eventType, callback, };
			if (event.eventTypeId == EventTypeId.AccQuarterliesQ4) item = { icon: 'fa-chart-pie-alt', iconStyle, text, subText: event.eventType, callback, };
			if (event.eventTypeId == EventTypeId.AccShowcase) item = { icon: 'fa-award', iconStyle, text, subText: event.eventType, callback, };
		}

		return item;
	}



	private formatEventDates(startUTC: number, endUTC: number, locale: 'en-US' | 'es-US'): string {
		const startDate = this.util.date.formatUTC(startUTC, 'DOW MMM D, YYYY', 'No Time', locale);
		const endDate = this.util.date.formatUTC(endUTC, 'DOW MMM D, YYYY', 'No Time', locale);
		const startTime = this.util.date.formatUTC(startUTC, 'No Date', 'H:MM AM EST', locale);
		if (startDate == endDate) {
			const endTime = this.util.date.formatUTC(endUTC, 'No Date', 'H:MM AM EST', locale);
			return `${startDate} ${startTime.split(' ')[0]}-${endTime}`;
		} else {
			return `${startDate} ${startTime}`;
		}
	}

}