import { Component, inject } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterLink } from '@angular/router';
import { CommonButtonRowWidget } from '@eforall/common';
import { MobileFrameService, MobilePagePart } from '@eforall/mobile';
import { LearningLabelsService } from './learning-labels.service';

@Component({
	selector: 'learning-page',
	standalone: true,
	imports: [
		MobilePagePart,
		RouterLink,
		CommonButtonRowWidget,
		FormsModule,
	],
	templateUrl: './learning.page.html'
})

export class LearningPage {
	private frame = inject(MobileFrameService);
	public labels = inject(LearningLabelsService);

	constructor() {
		this.frame.setUrlMetadata({ url: `/learning`, pageName: 'Learning', backUrl: `/` });
	}

}