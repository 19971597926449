import { Component, computed, inject, OnInit, signal } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { CommonButtonRowWidget, UrlService, UtilityService } from '@eforall/common';
import { MobileFrameService, MobileListWidget, MobileListWithSubItem, MobilePagePart } from '@eforall/mobile';
import { FuncService } from '../../services';
import { SurveysLabelsService } from './surveys-labels.service';

@Component({
	selector: 'surveys-page',
	standalone: true,
	imports: [
		MobilePagePart,
		RouterLink,
		CommonButtonRowWidget,
		FormsModule,
		MobileListWidget,
	],
	templateUrl: './surveys.page.html'
})

export class SurveysPage implements OnInit {
	private frame = inject(MobileFrameService);
	private func = inject(FuncService);
	private util = inject(UtilityService);
	private urlService = inject(UrlService);
	private router = inject(Router);
	private route = inject(ActivatedRoute);
	public labels = inject(SurveysLabelsService);
	public readonly surveysList = signal<MobileListWithSubItem[]>([]);


	public infoContent = computed(() => {
		const count = this.surveysList().length;
		return count > 0 ? this.util.text.singularOrPluralWithCount(count, 'Open Survey', 'Open Surveys') : `No Open Surveys`;
	});

	constructor() {
		this.frame.setUrlMetadata({ url: `/surveys`, pageName: 'Surveys', backUrl: `/` });
	}


	async ngOnInit() {

		const accSessions = await this.func.surveys.get();

		const list = accSessions.reduce((a: MobileListWithSubItem[], accSession) => {
			a.push({
				icon: accSession.accSessionSurveyResponseId ? `fa-circle color-green` : `fa-circle color-eforall-blue`,
				iconStyle: 'fa-solid',
				text: this.labels.accSessionSurvey() ?? '',
				subText: accSession.accSessionSurveyResponseId ? 'Complete' : 'Closes: ' + this.util.date.formatUTC(accSession.toolEndUTC, 'MM/DD/YYYY', 'H:MM AM EST', this.urlService.isSpanish() ? 'es-US' : 'en-US'),
				callback: () => {
					this.router.navigate(['acc-session', accSession.accSessionId], { relativeTo: this.route });
				},
			});

			return a;

		}, []);

		this.surveysList.set(list);

	}

}