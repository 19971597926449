import { Routes } from '@angular/router';
import { MobileError404Page } from '@eforall/mobile';
import { PublicMobileFrame } from './frame/public-mobile/public-mobile.frame';
import { AgreementPage, AgreementsPage, ApplicationAboutYouPage, ApplicationApplyAcceleratorPage, ApplicationApplyPitchPage, ApplicationJoinCodePage, ApplicationJoinPage, ApplicationPage, ApplicationQuestionPage, ApplicationYourBusinessPage, ApplicationYourTeamPage, ApplicationsPage, CalendarPage, CohortMyTeamPage, CohortPage, ExampleButtonsPage, ExampleFieldsPage, ExampleTextPage, ExamplesPage, FeedbackPage, GoalsPage, HomePage, LearningPage, ProfileBasicInfoPage, ProfileBiosPage, ProfileDemographicsPage, ProfileEmailsPage, ProfileLanguagePage, ProfilePage, ProfileSocialLinksPage, ProgramsPage, SurveyAccSessionPage, SurveysPage } from './pages';

export const routes: Routes = [
	{
		path: '',
		component: PublicMobileFrame,
		children: [
			{ path: '', component: HomePage },
			{ path: 'programs', component: ProgramsPage },
			{ path: 'feedback', component: FeedbackPage },
			{ path: 'calendar', component: CalendarPage },
			{ path: 'learning', component: LearningPage },
			{ path: 'goals', component: GoalsPage },
			{
				path: 'surveys',
				children: [
					{ path: '', component: SurveysPage },
					{ path: 'acc-session/:accSessionId', component: SurveyAccSessionPage, },
				]
			},
			{
				path: 'cohort',
				children: [
					{ path: '', component: CohortPage },
					{ path: 'my-team', component: CohortMyTeamPage, },
				]
			},
			{
				path: 'agreements',
				children: [
					{ path: '', component: AgreementsPage },
					{ path: 'entrepreneur-handbook', component: AgreementPage, },
					{ path: 'media-consent', component: AgreementPage, },
				]
			},

			{
				path: 'applications',
				children: [
					{ path: '', component: ApplicationsPage },
					{ path: 'apply-accelerator', component: ApplicationApplyAcceleratorPage },
					{ path: 'apply-pitch', component: ApplicationApplyPitchPage },
					{
						path: 'join',
						children: [
							{ path: '', component: ApplicationJoinPage },
							{ path: ':applCode', component: ApplicationJoinCodePage }
						],
					},
					{
						path: ':applicationId',
						children: [
							{ path: '', component: ApplicationPage, },
							{ path: 'your-business', component: ApplicationYourBusinessPage, },
							{ path: 'you', component: ApplicationAboutYouPage, },
							{ path: 'your-team', component: ApplicationYourTeamPage, },
							{ path: 'questions/:questionId', component: ApplicationQuestionPage, },
						],
					},
				]
			},
			{
				path: 'profile',
				children: [
					{ path: '', component: ProfilePage },
					{ path: 'info', component: ProfileBasicInfoPage },
					{ path: 'demographics', component: ProfileDemographicsPage },
					{ path: 'emails', component: ProfileEmailsPage },
					{ path: 'bios', component: ProfileBiosPage },
					{ path: 'social-links', component: ProfileSocialLinksPage },
					{ path: 'language', component: ProfileLanguagePage },
				]
			},

			{
				path: 'examples',
				children: [
					{ path: '', component: ExamplesPage },
					{ path: 'fields', component: ExampleFieldsPage },
					{ path: 'text', component: ExampleTextPage },
					{ path: 'buttons', component: ExampleButtonsPage },
				]
			},
			{ path: '**', component: MobileError404Page }
		]
	},


];
