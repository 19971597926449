<mobile-page-part>


	<div class="info-content">
		<h3>{{applicationService.application()!.application.companyName}}</h3>
	</div>

	@if(!applicationService.application()?.isCoParticipant)
	{
	<common-button-column>
		<button class="btn btn-large"
			[ngClass]="{ 'btn-primary': primaryBtn() === 'instructions',  'btn-standard': primaryBtn() !== 'instructions',}"
			(click)="applicationService.openInstructionFlyout(instructions())">Instructions</button>
	</common-button-column>

	<common-text-field [field]="form.businessName"></common-text-field>
	<common-select-field [field]="form.businessType"></common-select-field>
	<common-select-field [field]="form.industry"></common-select-field>
	<common-select-field [field]="form.offering"></common-select-field>
	<common-number-field [field]="form.annualRevenue"></common-number-field>
	<common-number-field [field]="form.numFullTime"></common-number-field>
	<common-number-field [field]="form.numPartTime"></common-number-field>
	<common-number-field [field]="form.numContractors"></common-number-field>
	<common-phone-field [field]="form.businessPhone"></common-phone-field>
	<common-address-field [field]="form.businessAddress"></common-address-field>



	<common-button-column>
		<button class="btn btn-large"
			[ngClass]="{ 'btn-primary': primaryBtn() === 'next-step',  'btn-standard': primaryBtn() !== 'next-step',}"
			(click)="applicationService.navigateTo({step:'you'})"
			[disabled]="!form.aboutBusinessComplete()">Next
			Step</button>
	</common-button-column>
	}

	@else {
	<common-message alertType="warning">Only the applicant can edit business details. TBD</common-message>
	}
</mobile-page-part>