import { Component, computed, inject } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonButtonColumnWidget, CommonSelectFieldWidget, CommonTextFieldWidget } from '@eforall/common';
import { LanguageId } from '@interfaces';
import { ApplicationApplyBusinessFormService } from './company-form.service';

@Component({
	selector: 'application-company-part',
	standalone: true,
	imports: [
		CommonButtonColumnWidget,
		CommonTextFieldWidget,
		CommonSelectFieldWidget,
		FormsModule,
	],
	templateUrl: './company.part.html'
})

export class ApplicationCompanyPart {
	public form = inject(ApplicationApplyBusinessFormService);


	public readonly alreadyAppliedForBy = computed(() => this.form.applyService.urlService.isSpanish() ? 'Ya solicitado por' : 'Already applied for by');


	public descriptionAndLink = computed(() => {
		const program = this.form.applyService.selectedApplicationProgramInstance();
		if (program) {
			if (program.accId) {
				if (program.languageId == LanguageId.English) return { description: `The EforAll Accelerator Program is a free, one-year program offered twice a year in each of our communities. This program offers a unique combination of immersive business training, mentorship and access to an extended professional network. Anyone with a dream to start or grow a business or nonprofit is encouraged to apply. `, link: `https://eforall.org/business-accelerator/` };
				else if (program.languageId == LanguageId.Spanish) return { description: `El acelerador de EparaTodos conocido como Negocios Exitosos es un programa gratuito de un año que se ofrece dos veces por año en cada una de nuestras comunidades. Este programa ofrece una combinación única de capacitación y mentoría inmersivas, y acceso a una amplia red profesional. Cualquiera que sueñe con iniciar o desarrollar un negocio u organización sin fines de lucro puede postular.`, link: `https://eforall.org/es/acelerador-de-negocios/` };
			}
			if (program.picId) {
				if (program.languageId == LanguageId.English) return { description: `The EforAll Pitch  Contest Program is a fast-paced, fun community event that help early-stage entrepreneurs gain valuable exposure and feedback on a business idea. Great opportunity to network, validate a business idea, and compete to win a cash prize. It is free for entrepreneurs to apply and free for all to attend.`, link: `https://eforall.org/pitch-contests/` };
				else if (program.languageId == LanguageId.Spanish) return { description: `El Concursos Lanza Tu Idea de EparaTodos es un evento para la comunidad vertiginosos y divertidos que ayudan a los emprendedores en las etapas iniciales a obtener exposición valiosa y comentarios sobre una idea de negocios. Excelente oportunidad para crear una red empresarial, validar una idea de negocios y competir para ganar un premio en efectivo. Los emprendedores pueden postularse sin costa alguno y todos pueden asistir gratis.`, link: `https://eforall.org/es/lanza-tu-idea/` };
			}

		}
		return { description: '', link: `` };
	});


}