import { Component, computed, inject } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonDateTimeFieldWidget, CommonMultiselectFieldWidget, CommonSelectFieldWidget, CommonTextFieldWidget } from '@eforall/common';
import { MobileFrameService, MobilePagePart } from '@eforall/mobile';
import { MarkdownModule, provideMarkdown } from 'ngx-markdown';
import { ProfileDemographicsFormService } from './demographics-form.service';


@Component({
	selector: 'profile-demographics-page',
	standalone: true,
	imports: [
		MobilePagePart,
		FormsModule,
		MarkdownModule,
		CommonSelectFieldWidget,
		CommonMultiselectFieldWidget,
		CommonTextFieldWidget,
		CommonDateTimeFieldWidget,
	],
	providers: [provideMarkdown()],
	templateUrl: './demographics.page.html'
})

export class ProfileDemographicsPage {
	private frame = inject(MobileFrameService);
	public readonly form = inject(ProfileDemographicsFormService);

	public readonly showOtherGender = computed(() => {
		if (this.form.gender.actualValue() === 'O') return true;
		else return false;
	});


	public demoInfo = 'We compile this information **in bulk** and use it to demonstrate to our funders the people with whom we engage. **Please** consider telling us about yourself. We **promise** to never share your individual demographic information.\n\nWe believe entrepreneurship is for **all**.';


	constructor() {
		this.frame.setUrlMetadata({ url: '/profile/demographics', pageName: 'Demographics', backUrl: '/profile' });
	}

}