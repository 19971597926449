import { Injectable, Signal, computed } from "@angular/core";
import { BaseLabelService } from "../../services";

@Injectable({ providedIn: 'root' })
export class SurveysLabelsService extends BaseLabelService {

	noSurveyOpen = computed(() => this.getLabel([
		'No Survey open at this time',
		'No Survey open at this time'
	]));

	accSessionSurvey = computed(() => this.getLabel([
		'Accelerator Session Survey',
		'Sesión de aceleración Survey'
	]));

	acceleratorSession = computed(() => this.getLabel([
		'Accelerator Session',
		'Sesión de aceleración'
	]));

	topic = computed(() => this.getLabel([
		'Topic',
		'Tema'
	]));

	specialist = computed(() => this.getLabel([
		'Specialist',
		'Especialista'
	]));

	sessionSurveyAlreadySubmitted = computed(() => this.getLabel([
		'Congratulations! You have already submitted this survey.',
		'¡Felicidades! Ya ha enviado esta encuesta.',
	]));

	mdSessionSurveyNotAttendee = computed(() => this.getLabel([
		'You do not have access to this surevy as you are not an attendee for this session for {{acc-name}}. If you need assistance, reach out to the Program Manager at {{pm-email}}.',
		'No tiene acceso a este seguro ya que no es un asistente a esta sesión para {{acc-name}}. Si necesita ayuda, comuníquese con el gerente del programa en {{pm-email}}.',
	]));

	mdSessionSurveyNotOpenYet = computed(() => this.getLabel([
		'The survey is not yet available. Please return in {{time-period}}. If you need assistance, reach out to the PM at {{pm-email}}.',
		'La encuesta aún no está disponible. Vuelve en {{time-period}}. Si necesita ayuda, comuníquese con el PM en {{pm-email}}.',
	]));

	mdSessionSurveyClosed = computed(() => this.getLabel([
		'The survey is no longer available, as of {{time-period}} ago. If you need assistance, reach out to the Program Manager at {{pm-email}}.',
		'La encuesta ya no está disponible desde hace {{time-period}}. Si necesita ayuda, comuníquese con el gerente del programa en {{pm-email}}.',
	]));

	mdSessionSurveyOpen = computed(() => this.getLabel([
		'Congratulations on attending this session. You have until {{close-date}} to fill out the survey.',
		'¡Felicitaciones por asistir a esta sesión! Tiene hasta {{close-date}} para llenar esta encuesta.',
	]));

	surveyAnswerAllQuestionsMsg = computed(() => this.getLabel([
		'Please answer all questions to be able to submit.',
		'Por favor conteste todas las preguntas para poder enviar.',
	]));


	topicLongNameLabel: { [index: string]: Signal<string | undefined> } = {
		'Accelerator Kick Off': computed(() => this.getLabel(['Accelerator Kick Off', 'Bienvenida'])),
		'Business Models and Customer Segments': computed(() => this.getLabel(['Business Models and Customer Segments', 'Modelos de negocio y Segmentos de clientes'])),
		'Connecting Customers to Value': computed(() => this.getLabel(['Connecting Customers to Value', 'Conectando las necesidades del cliente con mi producto'])),
		'Prioritizing Customers Testing Assumptions': computed(() => this.getLabel(['Prioritizing Customers and Testing Assumptions', 'Priorización de clientes y validacion de supuestos'])),
		'Testing Assumptions Through Surveys': computed(() => this.getLabel(['Testing Assumptions Through Surveys', 'Prueba de supuestos a través de encuestas '])),
		'Setting Goals and Working through Surveys': computed(() => this.getLabel(['Setting Goals and Working through Surveys', 'Establecimiento de metas y elaboración de encuestas'])),
		'Testing Profitability Quick Calculations': computed(() => this.getLabel(['Testing Profitability through Quick Calculations', 'Cálculos rápidos para determinar ganancias'])),
		'Checking In and Planning Ahead': computed(() => this.getLabel(['Checking In and Planning Ahead', 'Revision del programa hasta ahora y planificación de los proximos meses'])),
		'Exploring Pricing Strategies': computed(() => this.getLabel(['Exploring Pricing Strategies', 'Exploración de estrategias de precios'])),
		'Entrepreneur Tell All/Open Class': computed(() => this.getLabel(['Entrepreneur Tell All/Open Class', 'Clase abierta- Sugerencias: Emprendedor Invitado o Herramientas en Línea/Google Suite, etc.'])),
		'Progress Pitch': computed(() => this.getLabel(['Progress Pitch', 'Presentación del progreso'])),
		'Branding and Messaging': computed(() => this.getLabel(['Branding and Messaging', 'Creación de la marca (Branding) y el mensaje'])),
		'Forecasting Revenues and Costs': computed(() => this.getLabel(['Forecasting Revenues and Costs', 'Prevision de costos e ingresos'])),
		'Legal Issues of Business': computed(() => this.getLabel(['Legal Issues of Business', 'Aspectos legales'])),
		'Accounting': computed(() => this.getLabel(['Accounting', 'Contabilidad'])),
		'Insurance': computed(() => this.getLabel(['Insurance', 'Seguros'])),
		'Reaching Customers through Websites': computed(() => this.getLabel(['Reaching Customers through Websites', 'Llegar a los clientes a través de paginas web'])),
		'Marketing and Social Media': computed(() => this.getLabel(['Marketing and Social Media', 'Comercialización (Marketing) y redes sociales'])),
		'Asking for Money': computed(() => this.getLabel(['Asking for Money', 'Acceso a capital'])),
		'Presentation Skills': computed(() => this.getLabel(['Presentation Skills', 'Aptitudes de presentación (hablar en público)'])),
		'Marketing through Media Outlets': computed(() => this.getLabel(['Marketing through Media Outlets', 'Marketing a través de los medios'])),
		'Purpose and Impact of Your Business': computed(() => this.getLabel(['Exploring the Purpose and Impact of Your Business', 'Explorando el propósito y el impacto de su negocio'])),
		'Selling to your Customer': computed(() => this.getLabel(['Selling to your Customer', 'Vender a su cliente'])),
		'Practice Presentations': computed(() => this.getLabel(['Practice Presentations', 'Práctica para la Presentación Final'])),
		'Final Presentation Practice': computed(() => this.getLabel(['Final Presentation Practice', 'Práctica para la Presentación Final'])),
	};

	questions = {
		noSurveyQuestionsMsg: computed(() => this.getLabel([
			'This topic does not have any survey questions.',
			'Este tema no tiene preguntas de encuesta.',
		])),
		iWasAbleToEasilyAttendThisSession: computed(() => this.getLabel([
			'I was able to easily attend this session.',
			'Me fue fácil asistir a esta clase.',
		])),
		iReceivedAllInformationOfSession: computed(() => this.getLabel([
			'I received all the information I needed ahead of the session.',
			'Recibí toda la información necesaria antes de la clase.',
		])),
		theSessionWasWellFacilitated: computed(() => this.getLabel([
			'The session was well facilitated by EforAll staff.',
			'La clase estuvo bien facilitada por el personal de EparaTodos.',
		])),
		sessionLogisticsOptional: computed(() => this.getLabel([
			'What else would you like to say about the session logistics? (optional)',
			'¿Tiene algún comentario acerca de la logística/organización de esta clase? (opcional)',
		])),
		iWasPresentForThisTopic: computed(() => this.getLabel([
			'I was present for this topic',
			'Estuve presente en este tema',
		])),
		thisTopicIsImportantForMyBusiness: computed(() => this.getLabel([
			'This topic is important for my business / non-profit.',
			'Este tema es importante para mi negocio / organización sin fines de lucro.',
		])),
		iWillUseThisTopicNextThreeMonths: computed(() => this.getLabel([
			'I will use this topic knowledge within the next three months.',
			'Usaré el conocimiento adquirido en este tema dentro de los próximos tres meses.',
		])),
		iHaveBetterUnderstandingOfTopic: computed(() => this.getLabel([
			'I have a better understanding of this topic and can more confidently apply it.',
			'Tengo mejor entendimiento de este tema y puedo aplicarlo con más confianza en mi emprendimiento.',
		])),
		thereWasAdequateTimeDedicatedToTopic: computed(() => this.getLabel([
			'There was adequate time dedicated to this topic.',
			'El tiempo dedicado a este tema fue el adecuado.',
		])),
		sayAboutTopicOptional: computed(() => this.getLabel([
			'What else would you like to say about this topic? (optional)',
			'¿Tiene algún comentario acerca de este tema visto en clase? (opcional)',
		])),
		iWasPresentForThisSpecialist: computed(() => this.getLabel([
			'I was present for this specialist',
			'Estuve presente para este especialista',
		])),
		theSpecialistWasProfessional: computed(() => this.getLabel([
			'The specialist was professional.',
			'El especialista se comportó profesionalmente durante la clase.',
		])),
		theSpecialistWasKnowledgeable: computed(() => this.getLabel([
			'The specialist was knowledgeable about this topic.',
			'El especialista tiene buen conocimiento del tema.',
		])),
		theSpecialistEffectivelyTaughtTheTopic: computed(() => this.getLabel([
			'The specialist effectively taught the topic.',
			'El especialista enseñó de manera efectiva el tema de la clase',
		])),
		sayAboutSpecialistOptional: computed(() => this.getLabel([
			'What else would you like to say about this specialist? (optional)',
			'¿Tiene algún comentario acerca de este especialista? (opcional)',
		])),
	};








}