import { Component, ElementRef, OnInit, computed, inject, input, viewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { UtilityService } from '../../../../services';
import { CommonFieldWidgetBase } from '../common-field-widget-base';
import { CommonDateTimeField } from '../field';

@Component({
	selector: 'common-date-time-field',
	imports: [FormsModule],
	standalone: true,
	templateUrl: './common-date-time-field.widget.html',
})
export class CommonDateTimeFieldWidget extends CommonFieldWidgetBase<number | undefined, CommonDateTimeField> implements OnInit {

	private util = inject(UtilityService);
	public readonly field = input.required<CommonDateTimeField>();
	public readonly dateFieldId = `date-field-${Math.floor(Math.random() * 1000)}`;
	public readonly dateField = viewChild<ElementRef<HTMLInputElement>>('dateField');
	public readonly type = computed(() => this.field().config().includeTime ? 'datetime-local' : 'date');

	public maxDate = computed(() => {
		const config = this.field().config();
		let newDate = new Date();
		let dateStr = '';

		if (config.type === 'birthday') { dateStr = this.util.date.adjustDateByYears(18, 'minus'); }
		else if (config.type === 'event') {
			if (config.baseDateUTC) { newDate = this.util.date.fromUTC(config.baseDateUTC)!; }
			dateStr = this.util.date.adjustDateByYears(2, 'plus', newDate, config.includeTime);
		}
		return dateStr;
	});

	public minDate = computed(() => {
		const config = this.field().config();
		let newDate = new Date();
		let dateStr = '';

		if (config.type === 'birthday') { dateStr = this.util.date.adjustDateByYears(80, 'minus'); }
		else if (config.type === 'event') {
			if (config.baseDateUTC) { newDate = this.util.date.fromUTC(config.baseDateUTC)!; }
			dateStr = this.util.date.adjustDateByYears(2, 'minus', newDate, config.includeTime);
		}

		return dateStr;
	});

	private readonly minUTC = computed(() => this.formatDatetoUTC(this.minDate(), this.field().config().includeTime));
	private readonly maxUTC = computed(() => this.formatDatetoUTC(this.maxDate(), this.field().config().includeTime));



	private readonly labels = computed(() => {
		const config = this.field().config();
		const minUTC = this.minUTC();
		const maxUTC = this.maxUTC();

		return config.isSpanish ? {
			missingRequired: `Required field`,
			minDate: minUTC ? `Date must be ${this.util.date.formatUTC(minUTC, 'MM/DD/YYYY', config.includeTime ? 'H:MM AM EST' : 'No Time', 'es-US')} or after.` : '',
			maxDate: maxUTC ? `Date must be ${this.util.date.formatUTC(maxUTC, 'MM/DD/YYYY', config.includeTime ? 'H:MM AM EST' : 'No Time', 'es-US')} or earlier.` : '',
			notValid: `Invalid Date`,
		} : {
			missingRequired: `Required field`,
			minDate: minUTC ? `Date must be ${this.util.date.formatUTC(minUTC, 'MM/DD/YYYY', config.includeTime ? 'H:MM AM EST' : 'No Time', 'en-US')} or after.` : '',
			maxDate: maxUTC ? `Date must be ${this.util.date.formatUTC(maxUTC, 'MM/DD/YYYY', config.includeTime ? 'H:MM AM EST' : 'No Time', 'en-US')} or earlier.` : '',
			notValid: `Invalid Date`,
		};
	});

	public readonly renderedModel = computed(() => {
		const value = this.field().pendingValue() ?? this.field().actualValue();
		return this.render(value);
	});


	public errorToDisplay = computed(() => {
		const error = this.field().error();
		if (error == '') return '';
		if (this.hasFocus() || !this.hadFocus()) return '';
		return error;
	});


	ngOnInit() {
		this.field().error.set(this.validate());
	}


	public async onBlur() {
		await this.blur(this.field());
	}


	protected override cleanValue(value: number | undefined): number | undefined {
		const nativeElement = this.dateField()?.nativeElement;
		const valueAsUTC = nativeElement?.value ? this.formatDatetoUTC(nativeElement.value, this.field().config().includeTime) : undefined;
		return valueAsUTC;
	}

	protected override render(value: number | undefined) {
		if (this.hasFocus() || this.field().error()) return this.dateField()?.nativeElement.value;
		if (value) {
			return this.fromUTCToDateStr(value);
		}
		return '';
	}


	protected validate(): string {
		const nativeElement = this.dateField()?.nativeElement;
		const config = this.field().config();
		const labels = this.labels();
		const minUTC = this.minUTC();
		const maxUTC = this.maxUTC();

		if (nativeElement) {
			if (!nativeElement.value.length && config.required) return labels.missingRequired;
			if (!nativeElement.value.length && !!nativeElement.validationMessage) return labels.notValid;

			if (nativeElement.value.length && !this.isValidDate(nativeElement.value, config.includeTime)) return labels.notValid;

			const valueUTC = this.formatDatetoUTC(nativeElement.value, config.includeTime);
			if (valueUTC && minUTC && valueUTC < minUTC) return labels.minDate;
			if (valueUTC && maxUTC && valueUTC > maxUTC) return labels.maxDate;

		}
		return '';
	}

	private formatDatetoUTC(value: string | undefined, includeTime: boolean) {
		if (!value || !value.length) return undefined;

		const [datePart, timePart] = value.split('T');
		const dateParts = datePart!.split('-');
		const year = +dateParts[0]!;
		const month = +dateParts[1]!;
		const day = +dateParts[2]!;

		if (isNaN(year) || isNaN(month) || isNaN(day)) {
			return undefined;
		}

		let hours = 0, minutes = 0;
		if (includeTime && timePart) {
			const timeParts = timePart!.split(':');
			const hoursStr = +timeParts[0]!;
			const minutesStr = +timeParts[1]!;
			if (!isNaN(hoursStr) && !isNaN(minutesStr)) {
				hours = hoursStr;
				minutes = minutesStr;
			}
		}

		return new Date(year, month - 1, day, hours, minutes).getTime() / 1000;
	}

	private fromUTCToDateStr(value: number | undefined) {
		if (value === undefined) return '';

		const date = this.util.date.fromUTC(value);
		return this.util.date.adjustDateByYears(0, 'plus', date, this.field().config().includeTime);
	}

	private isValidDate(dateString: string, includeTime: boolean): boolean {
		const regex = includeTime ? /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}$/ : /^\d{4}-\d{2}-\d{2}$/;
		if (!regex.test(dateString)) {
			return false;
		}

		const [datePart, timePart] = dateString!.split('T');
		const dateParts = datePart!.split('-');
		const year = +dateParts[0]!;
		const month = +dateParts[1]!;
		const day = +dateParts[2]!;

		if (isNaN(year) || isNaN(month) || isNaN(day)) {
			return false;
		}

		if (includeTime && timePart) {
			const timeParts = timePart!.split(':');
			const hours = +timeParts[0]!;
			const minutes = +timeParts[1]!;
			if (isNaN(hours) || isNaN(minutes)) {
				return false;
			}
		}

		const date = new Date(year, month - 1, day);

		if (includeTime && timePart) {
			const timeParts = timePart!.split(':');
			const hours = +timeParts[0]!;
			const minutes = +timeParts[1]!;
			date.setHours(hours);
			date.setMinutes(minutes);
		}

		return (
			date.getFullYear() === year &&
			date.getMonth() === month - 1 &&
			date.getDate() === day
		);
	}
}
