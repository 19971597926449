<mobile-page-part>

	<div class="info-content">
		@if(agreement(); as agreement){
		<h3>{{agreement.name}}</h3>
		}
		@else {<small>Agreement does not exists for the user.</small>}
	</div>

	@if(agreement(); as agreement){

	@if(agreement.signedDate){
	<common-message>{{labelsService.agreementThankYouMsg()}} {{agreement.signedDate}}</common-message>
	}

	<div class="d-flex flex-column gap-3">

		<div ngbAccordion>
			<div ngbAccordionItem [collapsed]="false">
				<div ngbAccordionHeader>
					<button ngbAccordionButton
						class="btn btn-flat p-2">
						@if(!agreement.signedDate){
						Please read and sign below
						}
						@else {
						Thank you
						}
					</button>
				</div>
				<div ngbAccordionCollapse>
					<div ngbAccordionBody class="p-2">
						<markdown class="agreement-text" [data]="agreement.introduction"></markdown>
					</div>
				</div>
			</div>

		</div>

		<markdown class="agreement-text" [data]="agreement.agreement"></markdown>


		@if(!agreement.signedDate){
		<div class="grey-box">

			<div class="mb-2 d-flex flex-row gap-2">
				<input class="form-check-input fs-6" type="checkbox" name="field-checkbox-" id="acknowledgeCheckbox"
					[ngModel]="isAcknowledgeChecked()" (ngModelChange)="isAcknowledgeChecked.set($event)">
				<label class="ps-2 form-check-label fs-6" for="acknowledgeCheckbox">
					{{labelsService.agreementCheckboxMsg()}}
				</label>
			</div>

			@if(isAcknowledgeChecked()){
			<div class="d-flex flex-column gap-3">
				{{labelsService.agreementDisclaimerMsg()}}

				<div>
					<div class="input-group">
						<span class="input-group-text" id="NameSignature">
							@if(errorToShow() !== ''){<i class="fa-fw fa-solid fa-circle-xmark color-darkred"></i>}
							@else{
							<i class="fa-fw fa-regular fa-file-signature"></i>
							}
						</span>
						<input type="text" class="form-control" [placeholder]="userName()" aria-label="NameSignature"
							[ngModel]="userNameFieldValue()" (ngModelChange)="onChange($event)" (blur)='onBlur()'
							(focus)="onFocus()" aria-describedby="NameSignature">
					</div>

					@if(errorToShow()!== ''){
					<div class="color-darkred text-end">{{errorToShow()}}</div>
					}
				</div>

				<common-button-row [center]="true">
					<button class="btn btn-primary btn-large" [disabled]="!valuesAreSame() || errorToShow() !== ''"
						(click)="signAgreement()">Sign</button>
				</common-button-row>


			</div>
			}
		</div>
		}

	</div>



	@if(agreement.signedDate){
	<common-message>{{labelsService.agreementThankYouMsg()}} {{agreement.signedDate}}</common-message>
	}

	}

</mobile-page-part>