import { Component, inject } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonAddressFieldWidget, CommonPhoneFieldWidget, CommonSelectFieldWidget, CommonTextFieldWidget } from '@eforall/common';
import { MobileFrameService, MobilePagePart } from '@eforall/mobile';
import { ProfileBasicInfoFormService } from './basic-info-form.service';

@Component({
	selector: 'profile-basic-info-page',
	standalone: true,
	imports: [
		MobilePagePart,
		FormsModule,
		CommonTextFieldWidget,
		CommonAddressFieldWidget,
		CommonPhoneFieldWidget,
		CommonSelectFieldWidget,
	],
	templateUrl: './basic-info.page.html'
})

export class ProfileBasicInfoPage {
	private readonly frame = inject(MobileFrameService);
	public readonly form = inject(ProfileBasicInfoFormService);


	constructor() {
		this.frame.setUrlMetadata({ url: '/profile/info', pageName: 'Info', backUrl: '/profile' });
	}

}