<mobile-page-part>

	<div class="info-content">
		<h3>{{infoContent()}}</h3>
	</div>

	@if(!surveysList().length){
	<div class="text-center color-grey">{{labels.noSurveyOpen()}}</div>
	}
	@else {
	<mobile-list [items]="surveysList()"></mobile-list>
	}


</mobile-page-part>