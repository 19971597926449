<mobile-page-part>

	<div class="info-content">
		<h3>{{calendarService.date()}}</h3>
	</div>

	<div>
		<h4>Ongoing Events</h4>

		@if(!calendarService.onGoingList().length){
		<div class="text-center color-grey"> No Ongoing Events</div>
		}
		@else {
		<mobile-list [items]="calendarService.onGoingList()"></mobile-list>
		}
	</div>
	<div>
		<h4>Upcoming Events</h4>
		@if(!calendarService.upcomingList().length){
		<div class="text-center color-grey"> No Upcoming Events</div>
		}
		@else {
		<mobile-list [items]="calendarService.upcomingList()"></mobile-list>
		}
	</div>
	<div>
		<h4>Past Events</h4>
		@if(!calendarService.pastList().length){
		<div class="text-center color-grey"> No Past Events</div>
		}
		@else {
		<mobile-list [items]="calendarService.pastList()"></mobile-list>
		}
	</div>
</mobile-page-part>