import { Component, OnInit, computed, effect, inject, input, signal } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { UtilityService } from '../../../../services';
import { CommonFieldWidgetBase } from '../common-field-widget-base';
import { CommonNumberField } from '../field';

@Component({
	selector: 'common-number-field',
	imports: [FormsModule],
	standalone: true,
	templateUrl: './common-number-field.widget.html',
})
export class CommonNumberFieldWidget extends CommonFieldWidgetBase<number | undefined, CommonNumberField> implements OnInit {

	public readonly field = input.required<CommonNumberField>();

	public min = computed<number>(() => this.field().config().min ?? 0);
	public max = computed<number>(() => this.field().config().max ?? 100);

	public readonly type = computed(() => this.hasFocus() ? 'number' : 'text')


	private readonly labels = computed(() => {
		const config = this.field().config();

		return config.isSpanish ? {
			missingRequired: 'Spanish Required',
			minLength: 'Spanish min length',
			maxLength: 'Spanish max length',
		} : {
			missingRequired: `Required field`,
			minLength: `Number must be greater than ${this.min()}.`,
			maxLength: `Number must be less than ${this.max()}.`,
		};

	});


	public readonly renderedModel = computed(() => {
		const value = this.field().pendingValue() ?? this.field().actualValue();
		return this.render(value);
	});


	ngOnInit() {
		this.field().error.set(this.validate());
	}


	public onChange(value: string) {
		const num = value === '' ? undefined : parseInt(value);
		this.field().pendingValue.set(num);
	}


	public async onBlur() {
		await this.blur(this.field());
	}
	
	protected override cleanValue(value: number | undefined): number | undefined {
		if (value && value > 0) value = Math.round(value);
		if (value == 0 && this.field().config().saveZeroAsNull) value = undefined;
		return value;
	}


	protected override render(value: number | undefined) {
		if (this.hasFocus() || this.field().error()) return value;
		return value?.toLocaleString();
	}


	protected validate(): string {

		let value = this.getValue(this.field());
		
		if (value && value > 0) value = Math.round(value);
		const config = this.field().config();
		const labels = this.labels();

		if (config.required && value == undefined && value == null) return labels.missingRequired;
		if (value !== undefined && value !== null && value > this.max()) return labels.maxLength;
		if (value !== undefined && value !== null && value < this.min()) return labels.minLength;

		return '';
	}
}