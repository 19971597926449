import { Component, inject } from '@angular/core';
import { MobileFrameService, MobilePagePart } from '@eforall/mobile';

@Component({
	selector: 'profile-social-links-page',
	standalone: true,
	imports: [
		MobilePagePart,
	],
	templateUrl: './social-links.page.html'
})

export class ProfileSocialLinksPage {
	private frame = inject(MobileFrameService);

	constructor() {
		this.frame.setUrlMetadata({ url: '/profile/social-links', pageName: 'Social Links', backUrl: '/profile' });
	}

}