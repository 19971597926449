import { Injectable, computed, inject, signal } from "@angular/core";
import { UrlService, UtilityService } from '@eforall/common';
import { DbDomainData } from "@interfaces";
import { FuncService } from "../func/func.service.gen";
import { domainDataEquality } from "./domain-data-equality";

export interface DomainData {
	industries: { industryId: number, label: string }[],
	industryMap: { [industryId: number]: { label: string } },
	companyTypes: { companyTypeId: number, label: string }[],
	companyTypeMap: { [companyTypeId: number]: { label: string } },
	webLinkTypes: { webLinkTypeId: number, label: string, order: number }[],
	webLinkTypeMap: { [webLinkTypeId: number]: { label: string } },
}

const EMPTY_DOMAIN_DATA: DbDomainData = {
	asOfUtc: 0,
	industries: [],
	webLinkTypes: [],
	companyTypes: [],
}

@Injectable({ providedIn: 'root' })
export class DomainDataService {

	private readonly urlService = inject(UrlService);
	private readonly func = inject(FuncService);
	private readonly util = inject(UtilityService);

	private readonly _dbData = signal<DbDomainData>(EMPTY_DOMAIN_DATA, { equal: domainDataEquality });
	public readonly data = computed(() => {

		const isSpanish = this.urlService.isSpanish();
		const db = this._dbData();

		const data: DomainData = {
			companyTypes: db.companyTypes.map(v => ({ companyTypeId: v.id, label: isSpanish ? v.es : v.en })),
			companyTypeMap: {},
			industries: db.industries.map(v => ({ industryId: v.id, label: isSpanish ? v.es : v.en })),
			industryMap: {},
			webLinkTypes: db.webLinkTypes.map(v => ({ webLinkTypeId: v.id, label: isSpanish ? v.es : v.en, order: v.order })),
			webLinkTypeMap: {},
		};

		return {
			...data,
			companyTypeMap: this.util.array.toMap(data.companyTypes, v => v.companyTypeId),
			industryMap: this.util.array.toMap(data.industries, v => v.industryId),
			webLinkTypeMap: this.util.array.toMap(data.webLinkTypes, v => v.webLinkTypeId),
		};

	});


	public async loadDomainData(): Promise<void> {
		this._dbData.set(await this.func.getDomainData());
	}


	public async clearDomainData(): Promise<void> {
		this._dbData.set(EMPTY_DOMAIN_DATA);
	}

}