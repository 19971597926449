<mobile-page-part>

	<div class="info-content">
		<h3>{{teamHeader()}}</h3>
	</div>


	@if(applicationService.application(); as application){
	@if(!application.isCoParticipant)
	{

	<common-button-column>
		<button class="btn btn-large"
			[ngClass]="{ 'btn-primary': primaryBtn() === 'instructions',  'btn-standard': primaryBtn() !== 'instructions',}"
			(click)="openInstructions()">Instructions</button>
	</common-button-column>

	<div>
		<h4>
			Team Size:
		</h4>
		<select class="form-select form-select-sm" [ngModel]="teamSize().value" (ngModelChange)="onChange($event)"
			[disabled]="disableTeamSize()">
			<option disabled value="">Select...</option>
			@for(option of teamSizeOptions; track option.value;)
			{
			<option [value]="option.value">{{option.text}}</option>
			}
		</select>
	</div>

	@if(teamSize().value === 'M'){



	<div class="question-answer-content">
		<h4>
			List name and a short bio of each member of your core team, whether they will participate or not.
		</h4>
		<common-text-field [field]="form.restOfTeam"></common-text-field>
	</div>


	<div>
		<h4>Are any of your team members participating fully in this program?</h4>
		<select class="form-select form-select-sm" [ngModel]="hasOrInviteParticipants()"
			(ngModelChange)="hasOrInviteParticipants.set($event)"
			[disabled]="applicationService.invitedParticipants().length > 0">
			<option disabled value="">Select...</option>
			<option value="Y">Yes</option>
			<option value="N">No</option>
		</select>
	</div>

	@if(hasOrInviteParticipants() === 'Y'){

	@if(applicationService.invitedParticipants().length){
	<div>
		<h4>Invited Participant(s)</h4>
		<mobile-list [items]="participantsList()"></mobile-list>
	</div>
	}
	@if(!form.showInviteForm())
	{
	<common-button-column>
		<button class="btn btn-large"
			[ngClass]="{ 'btn-primary': primaryBtn() === 'invite-participant',  'btn-standard': primaryBtn() !== 'invite-participant',}"
			(click)="form.showInviteForm.set(true)">Invite
			{{applicationService.invitedParticipants().length ? 'Another':''}}
			Participant</button>
	</common-button-column>
	}

	@if(form.showInviteForm()){

	<div class="white-box d-flex flex-column gap-3">
		<h4 class="text-center">Participant Details</h4>

		<common-text-field [field]="form.firstName"></common-text-field>
		<common-text-field [field]="form.lastName"></common-text-field>
		<common-text-field [field]="form.title"></common-text-field>
		<common-email-field [field]="form.email"></common-email-field>

		<common-button-row [center]="true">
			<button class="btn btn-primary" (click)="form.invite()">Invite</button>
			<button class="btn btn-standard" (click)="form.closeInvite()">Cancel</button>
		</common-button-row>

	</div>

	}
	}
	}

	<common-button-column>
		<button class="btn btn-large"
			[ngClass]="{ 'btn-primary': primaryBtn() === 'next-step',  'btn-standard': primaryBtn() !== 'next-step',}"
			(click)="applicationService.navigateTo({step:'questions', questionId: 0})" [disabled]="!showNextStep()">Next
			Step</button>
	</common-button-column>


	}
	@else if(application.isCoParticipant) {
	<common-message alertType="warning">Only the applicant can edit team details. TBD</common-message>
	}
	}
</mobile-page-part>