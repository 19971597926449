import { Component, inject } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { MobileFrameService, MobileListWidget, MobileListWithSubItem, MobilePagePart } from '@eforall/mobile';

@Component({
	selector: 'profile-page',
	standalone: true,
	imports: [
		MobilePagePart,
		MobileListWidget,
		RouterLink
	],
	templateUrl: './profile.page.html'
})

export class ProfilePage {
	private frame = inject(MobileFrameService);
	private router = inject(Router);

	public primaryList: MobileListWithSubItem[] = [
		{ icon: 'fa-id-card', iconStyle: 'fa-regular', text: 'Basic Info', callback: () => this.navigateTo('/profile/info'), subText: 'Name, Phone, Address' },
		{ icon: 'fa-dna', iconStyle: 'fa-regular', text: 'Demographics', callback: () => this.navigateTo('/profile/demographics'), subText: 'Gender, Race, Birthday, etc.' },
		{ icon: 'fa-envelope', iconStyle: 'fa-regular', text: 'Emails', callback: () => this.navigateTo('/profile/emails') },
		{ icon: 'fa-link', iconStyle: 'fa-regular', text: 'Social Links', callback: () => this.navigateTo('/profile/social-links') },
	];

	public secondaryList: MobileListWithSubItem[] = [
		{ icon: 'fa-file-contract', iconStyle: 'fa-regular', text: 'Signed Agreements', callback: () => this.navigateTo('/agreements') },
		{ icon: 'fa-gear', iconStyle: 'fa-regular', text: 'Notification Settings', callback: () => this.navigateTo('/profile') },
		{ icon: 'fa-language', iconStyle: 'fa-regular', text: 'App Language', callback: () => this.navigateTo('/profile') },
	];

	constructor() {
		this.frame.setUrlMetadata({ url: '/profile', pageName: 'Profile', backUrl: '/' });
	}

	navigateTo(path: string) {
		this.router.navigate([path]);
	}

}